import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInviteQuery } from '@gen2/api/invites/hooks';
import {
  LiveFormType,
  useSendInviteStore,
} from '@gen2/app/invites/send-invites/store';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useParams } from 'react-router-dom';
import { Container, Option, Title } from './create-form-modal.styled';

export type TCreateFormModalProps = {
  fileId: string;
  onClose: () => void;
  open: boolean;
  onSelected: (liveFormType: LiveFormType) => void;
  fileUrl: string;
};

export const CreateFormModal = ({
  open,
  onClose,
  onSelected,
  fileUrl,
}: TCreateFormModalProps) => {
  const store = useSendInviteStore();
  const params = useParams<{ inviteId: string }>();
  const { data } = useInviteQuery(store.invite.id || params.inviteId || '');

  return (
    <Dialog
      id="form-fillable-modal"
      open={open}
      onClose={onClose}
      aria-labelledby="form-fillable-modal-title"
      aria-describedby="form-fillable-modal-description"
      data-cy="form-fillable-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={onClose}
      />
      <DialogTitle id="dialog-title">Create Form</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="dialog-description"
          align="center"
          component="div"
        >
          {
            // If the file is still being processed, show a loading spinner
            !fileUrl ? (
              <>
                <Title>
                  {' '}
                  Please wait while we process this file. As soon as it is
                  completed, you will be able to add fillable fields to this
                  document.
                </Title>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: 1,
                  }}
                >
                  <CircularProgress color="inherit" size={30} />
                </Box>
              </>
            ) : (
              // Otherwise, show the form-fillable options
              <>
                <Title>
                  {' '}
                  Choose how your contacts will fill out this form:
                </Title>
                <Container>
                  <Option>
                    <span>
                      <FontAwesomeIcon icon={duotone('user')} size="3x" />
                    </span>
                    <p>
                      <strong>Each person </strong>fills their own version of
                      this form
                    </p>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={(data?.contacts?.length || 0) === 0}
                      onClick={() => onSelected('individual')}
                    >
                      Individually Filled
                    </Button>
                  </Option>
                  <Option>
                    <span>
                      <FontAwesomeIcon icon={duotone('users')} size="3x" />
                    </span>
                    <p>
                      <strong>Multiple people</strong> fill out the same
                      document
                    </p>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={(data?.contacts?.length || 0) <= 1}
                      onClick={() => onSelected('multi')}
                    >
                      One Form For All
                    </Button>
                  </Option>
                </Container>
              </>
            )
          }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
