import {
  faCircleDot,
  faFileSignature,
  faInputText,
  faSignature,
  faSquareCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnnotationTypeEnum } from '../../utils/types';
import { SidePanelButton } from '../sidePanel.styled';

export const DraggableAnnotation = ({
  type,
  label,
  onDragStart,
  onDragEnd,
  isDraggable = true,
}: {
  type: string;
  label: string;
  onDragStart: any;
  onDragEnd: any;
  isDraggable?: boolean;
}) => {
  let icon;
  switch (type) {
    case AnnotationTypeEnum.TextField:
      icon = faInputText;
      break;
    case AnnotationTypeEnum.SIGNATURE:
      icon = faFileSignature;
      break;
    case AnnotationTypeEnum.CheckBox:
      icon = faSquareCheck;
      break;
    case AnnotationTypeEnum.RadioButton:
      icon = faCircleDot;
      break;
    case AnnotationTypeEnum.INITIAL:
      icon = faSignature;
      break;
    default:
      icon = faInputText;
      break;
  }

  return (
    <SidePanelButton
      draggable={isDraggable}
      onDragStart={async (e) => isDraggable && (await onDragStart(e, type))}
      onDragEnd={(e) => isDraggable && onDragEnd(e, type)}
    >
      <FontAwesomeIcon style={{ display: 'inline' }} icon={icon} />
      <span style={{ display: 'inline' }}>{label}</span>
    </SidePanelButton>
  );
};

export function createDragImage(type: any, color: any): HTMLElement {
  let dragImage: HTMLElement = document.createElement('div');

  switch (type) {
    case AnnotationTypeEnum.SIGNATURE:
      dragImage.setAttribute('type', 'text');
      dragImage.className =
        'PSPDFKit-Annotation-Widget PSPDFKit-Annotation-Widget-Text';
      dragImage.style.width = '120px';
      dragImage.style.height = '60px';
      dragImage.style.backgroundColor = '#ecf0ffe6';
      dragImage.style.borderWidth = '1px';
      break;
    case AnnotationTypeEnum.INITIAL:
      dragImage.className =
        'PSPDFKit-Annotation-Widget PSPDFKit-Annotation-Widget-Text';
      dragImage.style.width = '120px';
      dragImage.style.height = '60px';
      dragImage.style.backgroundColor = '#ecf0ffe6';
      dragImage.style.borderWidth = '1px';
      break;
    case AnnotationTypeEnum.RadioButton:
      dragImage = document.createElement('input');
      dragImage.setAttribute('type', 'radio');
      dragImage.className =
        'PSPDFKit-Annotation-Widget PSPDFKit-Annotation-Widget-RadioButton';
      dragImage.style.width = '25px';
      dragImage.style.height = '25px';
      dragImage.style.backgroundColor = '#ecf0ffe6 !important';
      dragImage.style.border = '1px solid #7f96ed';
      dragImage.style.boxShadow = 'none  !important';
      dragImage.style.borderRadius = '50%';
      break;
    case AnnotationTypeEnum.CheckBox:
      dragImage = document.createElement('input');
      dragImage.setAttribute('type', 'checkbox');
      dragImage.className =
        'PSPDFKit-Annotation-Widget PSPDFKit-Annotation-Widget-CheckBox';
      dragImage.style.width = '25px';
      dragImage.style.height = '25px';
      dragImage.style.backgroundColor = '#ecf0ffe6 !important';
      dragImage.style.boxShadow = 'none !important';
      dragImage.style.borderWidth = '1px';
      dragImage.style.borderStyle = 'solid';
      dragImage.style.borderColor = '#7f96ed';
      break;
    case AnnotationTypeEnum.TextField:
      dragImage = document.createElement('input');
      dragImage.setAttribute('type', 'text');
      dragImage.className =
        'PSPDFKit-Annotation-Widget PSPDFKit-Annotation-Widget-Text';
      dragImage.style.width = '120px';
      dragImage.style.height = '40px';
      dragImage.style.backgroundColor = '#ecf0ffe6';
      dragImage.style.border = 'none';
      break;
  }

  return dragImage;
}
