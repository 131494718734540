import {
  createUser,
  getTeamMember,
  getTeamMemberList,
  getTeamMemberPositions,
  TeamMemberListPayload,
  updateUser,
} from '@gen2/api/team-member/api';
import { TPosition, TUser } from '@gen2/types/user';
import { useMutation, useQuery } from '@tanstack/react-query';
import { has } from 'lodash';

export enum Keys {
  queryTeamMemberList = 'getTeamMemberList',
  queryTeamMemberItem = 'getUserItem',
  queryTeamMember = 'getUser',
  queryTeamMemberPositions = 'getTeamMemberPositions',
}

export interface UserListResponse {
  data: { users: TUser[] };
  links: {
    first: string | null;
    last: string | null;
    next: string | null;
    prev: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  };
}

export const useTeamMemberListQuery = (search: TeamMemberListPayload) =>
  useQuery({
    queryKey: [Keys.queryTeamMemberList, search],
    queryFn: async () => {
      const { data } = await getTeamMemberList({
        ...search,
        page: search.page,
        per_page: search.per_page,
      });

      if (data && has(data, 'data')) {
        return {
          data: data.data.users as TUser[],
          links: data.links,
          meta: data.meta,
        };
      }
      return null;
    },
  });

export const useUserMutation = () => useMutation({ mutationFn: createUser });

export const useUpdateUserMutation = () =>
  useMutation({ mutationFn: updateUser });

export const useTeamMemberQuery = (userId: string) =>
  useQuery({
    queryKey: [Keys.queryTeamMember, userId],
    queryFn: async () => {
      const { data } = await getTeamMember(userId);

      if (data && has(data, 'data')) {
        return data.data.user as TUser;
      }
      return null;
    },
    enabled: !!userId,
  });

export const useTeamMemberPositionsQuery = () =>
  useQuery({
    queryKey: [Keys.queryTeamMemberPositions],
    queryFn: async () => {
      const { data } = await getTeamMemberPositions();

      return data.data.positions as TPosition[];
    },
  });
