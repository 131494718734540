import { rem } from "polished";
import styled from "styled-components";

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',

  '> span': {
    marginLeft: rem(5),
    marginRight: rem(10),
  },
});