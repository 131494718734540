/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from '@components/theme/gen2';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useCreateInviteRequestMutation,
  useInviteRequestsQuery,
} from '@gen2/api/invite-requests/hooks';
import {
  InvitesKeys,
  useDraftInviteMutation,
  useEditDraftInviteMutation,
  useSendInviteMutation,
} from '@gen2/api/invites/hooks';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { queryClient } from '@gen2/config';
import { useAuth, useRouter } from '@gen2/hooks';
import { ErrorResponse } from '@gen2/utils/formatMessage';
import { toEndDayOfOrgTimezone } from '@gen2/utils/time';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AxiosResponse } from 'axios';
import { has } from 'lodash';
import { useMemo } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ValidationError } from 'yup';
import { createDraftPayload, createEditDraftPayload } from './helpers';
import { saveDraftSchema, sendSchema } from './schema';
import { TDraftInviteForm } from './send-invites';
import { useSendInviteStore } from './store';

type SourceType = 'initial' | 'group';
export const useSendInvite = () => {
  const { getValues, setError, clearErrors } =
    useFormContext<TDraftInviteForm>();
  const { t } = useTranslation('sendInvite');
  const { showModal } = useActionModalStore();

  // mutations
  const { mutateAsync: createInviteAsync } = useDraftInviteMutation();
  const { mutate: editDraftInviteMutation, mutateAsync: updateInviteAsync } =
    useEditDraftInviteMutation();
  const { mutateAsync: sendInviteAsync, isLoading: isSending } =
    useSendInviteMutation();

  // state
  const store = useSendInviteStore();
  const { data: requests } = useInviteRequestsQuery(
    {
      inviteId: store.invite.id,
      'sort[order]': 'asc',
      'sort[column]': 'order',
    },
    {
      enabled: !!store.invite.id,
    },
  );

  // router
  const params = useParams<{ id: string }>();
  const inviteId = useMemo(() => {
    // for both new and edit
    return store.invite.id || params.id;
  }, [params.id, store.invite.id]);
  const navigate = useNavigate();

  const mapValidationErrors = (errors: ValidationError) => {
    errors.inner?.forEach((item: ValidationError) => {
      const { type, path, message } = item;

      if (path) {
        return setError(path as FieldPath<TDraftInviteForm>, {
          type,
          message: message,
        });
      }
    });
  };

  const onSave = async () => {
    if (!inviteId) return;

    const values = getValues();
    const newValues = {
      ...values,
      due_at: values.due_at ? toEndDayOfOrgTimezone(values.due_at) : null,
    };

    try {
      await saveDraftSchema.validate(newValues, { abortEarly: false });

      queryClient.cancelQueries([InvitesKeys.editDraftInvite]);

      editDraftInviteMutation(createEditDraftPayload(inviteId, newValues), {
        onSuccess: () => {
          store.setBannerMessage({
            severity: 'success',
            message: store.isEditSendInvite
              ? t('notDraftSaved')
              : t('draftSaved'),
          });

          clearErrors();
        },
        onError: (error: unknown) => {
          const err = error as AxiosResponse;
          store.setBannerMessage({
            severity: 'error',
            message: err.data.message,
          });
        },
      });
    } catch (error: unknown) {
      const validationError = error as ValidationError;

      if (!newValues.rawMessage && !newValues.subject) {
        store.setBannerMessage({
          severity: 'error',
          message: t('noMessageAndSubject') ?? '',
        });
      }

      mapValidationErrors(validationError);
    }
  };

  const onSend = async () => {
    if (!requests?.length) {
      store.setBannerMessage({
        severity: 'error',
        message: t('sendInviteRequestRequired') ?? '',
      });

      return;
    }

    const inviteContactIds = store.invite.contacts.reduce(
      (acc, contact) => ({ ...acc, [contact.id]: 0 }),
      {} as Record<string, number>,
    );

    const contactsPerRequestCount = requests?.reduce((acc, request) => {
      acc[request.id] = request.contacts?.length || 0;

      request.contacts?.forEach((contact) => {
        if (contact.id in inviteContactIds) {
          inviteContactIds[contact.id]++;
        }
      });

      return acc;
    }, {} as Record<string, number>);

    const contactsNotIncludedInAnyRequests = Object.entries(inviteContactIds)
      .filter(([, count]) => count === 0)
      .map(([id]) => id);

    if (
      Object.entries(contactsPerRequestCount).some(([, count]) => count === 0)
    ) {
      showModal({
        header: t('errors.requestHasNoContacts.title'),
        message: 'errors.requestHasNoContacts.message',
        closeButtonLabel: t('errors.requestHasNoContacts.close') ?? '',
      });

      return;
    }

    if (contactsNotIncludedInAnyRequests.length > 0) {
      const missingContactsString = store.invite.contacts
        .filter((contact) =>
          contactsNotIncludedInAnyRequests.includes(contact.id),
        )
        .map((contact) => `'${contact.first_name} ${contact.last_name}'`)
        .join(', ')
        .replace(/, ([^,]*)$/, ' and $1');

      showModal({
        header: t('editSendInvite.requestContact.notAssigned.title'),
        message: 'editSendInvite.requestContact.notAssigned.message',
        messageParams: { contactNames: missingContactsString },
        translationNamespace: 'sendInvite',
        closeButtonLabel:
          t('editSendInvite.requestContact.notAssigned.close') ?? '',
      });

      return;
    }

    const values = getValues();
    const newValues = {
      ...values,
      due_at: values.due_at ? toEndDayOfOrgTimezone(values.due_at) : null,
    };

    try {
      await sendSchema.validate(newValues, { abortEarly: false });

      try {
        // create and send when there is no invite id
        if (!inviteId) {
          const res = await createInviteAsync(createDraftPayload(newValues));
          if (res.status === 201) {
            const {
              data: {
                invite: { id },
              },
            } = res.data;
            await sendInviteAsync({
              data: createDraftPayload(newValues),
              id,
            });
          }
        } else {
          await updateInviteAsync(createEditDraftPayload(inviteId, newValues));
          await sendInviteAsync({
            data: createDraftPayload(newValues),
            id: inviteId,
          });
        }

        store.setBannerMessage({
          severity: 'success',
          message: 'Invite was sent successfully',
        });

        setTimeout(() => {
          navigate(`/success-sent/${inviteId}`);
        }, 1000);
      } catch (err: unknown) {
        const axiosError = err as AxiosResponse;
        if (axiosError) {
          const { status, data } = axiosError;
          if (status === 422) {
            const { errors } = data as ErrorResponse;
            if (errors && has(errors, 'from_user_id')) {
              showModal({
                header: t('editSendInvite.invalidPointOfContact'),
                rawMessage: data.message,
                closeButtonLabel:
                  t('editSendInvite.requestContact.notAssigned.close') ?? '',
              });
            } else {
              showModal({
                header: t('editSendInvite.requestContact.notAssigned.title'),
                rawMessage: data.message,
                closeButtonLabel:
                  t('editSendInvite.requestContact.notAssigned.close') ?? '',
              });
            }
          } else {
            //todo: catch some backend error if needed
            store.setBannerMessage({
              severity: 'error',
              message: 'send invite failed',
            });
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const validationError = error as ValidationError;

      store.setBannerMessage({
        severity: 'error',
        message: t('sendInviteRequired') ?? '',
      });

      mapValidationErrors(validationError);
    }
  };

  return {
    onSave,
    onSend,
    isSending,
  };
};

export const REQUEST_TITLE = 'New File Upload Request';

export const useSendInviteApi = () => {
  const navigate = useNavigate();
  const { mutateAsync: draftInviteMutation, isLoading: isCreateDraftLoading } =
    useDraftInviteMutation();
  const {
    mutateAsync: createInviteRequestMutation,
    isLoading: isCreateDraftRequestLoading,
  } = useCreateInviteRequestMutation();
  const { user } = useAuth();
  const router = useRouter();
  const store = useSendInviteStore();

  const onCreateDraft = async (values: TDraftInviteForm) => {
    queryClient.cancelQueries([InvitesKeys.createDraftInvite]);

    await draftInviteMutation(createDraftPayload(values), {
      onSuccess: async (res) => {
        const inviteId = res.data.data.invite.id;

        // create initial request
        await createInviteRequestMutation({
          inviteId,
          title: REQUEST_TITLE,
          type: 'file-upload',
        });

        // No more need to redirect to edit mode, invitedId for a new invite , and we must save inviteId into current context
        store.setContextInviteIdForNewInvite(inviteId);
      },
      onError: (err) => {
        console.error('Failed to create draft invite', err);
      },
    });
  };

  const onInitDraft = async (source: SourceType = 'initial') => {
    // Base payload for creating draft invite
    const payload: TDraftInviteForm = {
      from_user_id: user?.id ?? '',
      subject: 'Sample Subject',
      due_at: null,
    };

    // Add `contact_group_ids` if the source is 'group'
    if (source === 'group') {
      payload.contact_group_ids = [sessionStorage.getItem('groupRef') ?? ''];
    }

    await onCreateDraft(payload);
  };

  const onNavigateToDraft = (source: SourceType = 'initial') => {
    // Create a new draft if the user is already on the new invite page or edit invite page
    // and support onNavigateToDraft from view group
    const isEditInvite =
      router.pathname.startsWith('/send-invites/') &&
      router.pathname.split('/').length > 2;
    const isCreateInvite = router.pathname === '/send-invites';
    if (isCreateInvite || isEditInvite) {
      // adding popup for new invite
      toast((t) => (
        <Box data-cy="confirmation">
          <Typography variant="body2" noWrap>
            Are you sure you want to create a new invite?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              data-cy="no-button"
              variant="outlined"
              color="tertiary"
              size="small"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </Button>
            <Button
              data-cy="yes-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={async () => {
                if (isEditInvite) {
                  navigate('/send-invites');
                }
                store.reset();
                toast.loading('Processing...', { id: t.id });
                await onInitDraft();
                toast.dismiss(t.id);
                const tid = toast.success('New invite created successfully', {
                  position: 'bottom-right',
                  style: {
                    background: colors.success,
                    color: colors.white,
                  },
                  icon: <FontAwesomeIcon icon={regular('check-circle')} />,
                });

                setTimeout(() => {
                  toast.dismiss(tid);
                }, 2000);
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      ));
    } else {
      source === 'initial'
        ? navigate('/send-invites')
        : navigate('/send-invites?source=group');
    }
  };

  return {
    onNavigateToDraft,
    onInitDraft,
    onCreateDraft,
    isCreateDraftRequestLoading,
    isCreateDraftLoading,
  };
};
