import { colors, weights } from '@components/theme/gen2';
import {
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  styled,
} from '@mui/material';
import { rem } from 'polished';

interface IActionModalContainerProps extends DialogProps {
  $withMaxWidth: boolean;
}

export const ActionModalContainer = styled(Dialog)<IActionModalContainerProps>(({ $withMaxWidth }) => ({
  maxWidth: $withMaxWidth ? rem(464) : 'unset',
  margin: 'auto',
  '& strong': {
    textTransform: 'capitalize',
    fontWeight: weights.semiBold,
  },
  '& em': {
    fontWeight: weights.normal,
    fontStyle: 'normal',
    color: colors.blueDark1,
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
}));

export const ActionModalTitle = styled(DialogTitle)({
  fontSize: rem(20),
  fontWeight: weights.semiBold,
  color: colors.grayDark3,
});

export const ActionModalActions = styled(DialogActions)({
  backgroundColor: colors.grayLight3,
});
