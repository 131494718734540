import { useDeleteFilesMutation } from '@gen2/api/files/hooks';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { TInviteRequest } from '@gen2/api/invite-requests/api';
import { useDeleteInviteRequestMutation } from '@gen2/api/invite-requests/hooks';
import { FeatureFlags } from '@gen2/app/components/feature-flags/feature-flags';
import LoadTemplate from '@gen2/app/components/load-template/load-template';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { rem } from 'polished';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useConfirmationModalStore } from '../components/confirmation-modal/hooks/confirmation-modal-store';
import { useFileStore } from '../components/invite-request/hooks/file-store';
import { InviteRequest } from '../components/invite-request/invite-request';
import {
  defaultRequests,
  TInviteRequestsForm,
} from '../invites/send-invites/requests/requests';
import { inviteRequestsSchema } from '../invites/send-invites/requests/schema';
import { useRequestsStore } from '../invites/send-invites/requests/store';
import { RequestList, Title } from './follow-up.styled';

export const FollowUpRequestsContent = () => {
  const store = useSendInviteStore();
  const { fields: requests } = useFieldArray({
    name: 'requests',
  });
  const { toDeleteRequest, setToDeleteRequest } = useRequestsStore();
  const { setIsConfirmationModalOpen, setConfirmationModalProps } =
    useConfirmationModalStore();
  const { mutate: deleteInviteRequestMutation, isLoading: isRequestDeleting } =
    useDeleteInviteRequestMutation();
  const toast = useToast();
  const { t } = useTranslation('sendInvite');
  const { inviteId } = useParams<{
    inviteId: string;
    followUpId: string;
  }>();
  const { file: selectedFile, setFile: setSelectedFile } = useFileStore();
  const { mutate: deleteFilesMutation, isLoading: isFileDeleting } =
    useDeleteFilesMutation();

  const onDelete = useCallback(() => {
    if (toDeleteRequest) {
      deleteInviteRequestMutation(
        {
          inviteId: inviteId || '',
          requestIds: [toDeleteRequest.id],
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);

            toast.show({
              text: t('request.delete.success'),
              variant: 'success',
            });

            return null;
          },
          onError: () => {
            toast.show({
              text: t('request.delete.error'),
              variant: 'error',
            });
          },
          onSettled: () => {
            setToDeleteRequest(undefined);
            setIsConfirmationModalOpen(false);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteInviteRequestMutation,
    toDeleteRequest,
    setIsConfirmationModalOpen,
    toast,
  ]);

  const handleFileDelete = useCallback(() => {
    if (selectedFile) {
      deleteFilesMutation(
        {
          type: 'request',
          typeId: selectedFile.request.id,
          files: [{ id: selectedFile.id }],
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);

            toast.show({
              text: t('file.delete.success'),
              variant: 'success',
            });
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (err: any) => {
            toast.show({
              text: t('file.delete.error'),
              variant: 'error',
            });
          },
          onSettled: () => {
            setSelectedFile(undefined);
            setIsConfirmationModalOpen(false);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, toast]);

  useEffect(() => {
    if (!toDeleteRequest?.title) return;

    setConfirmationModalProps({
      onClose: () => setIsConfirmationModalOpen(false),
      onSubmit: onDelete,
      isLoading: isRequestDeleting,
      title: t('request.delete.title'),
      submitLabel: t('request.delete.buttonLabel'),
      submitColor: 'danger',
      message: t('request.delete.message', {
        requestName: toDeleteRequest.title,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isRequestDeleting,
    onDelete,
    toDeleteRequest?.title,
    setConfirmationModalProps,
    setIsConfirmationModalOpen,
  ]);

  useEffect(() => {
    if (!selectedFile) return;

    const isDeleteFileFromFormType = selectedFile.request.type === 'live-form';

    setConfirmationModalProps({
      onClose: () => setIsConfirmationModalOpen(false),
      onSubmit: handleFileDelete,
      isLoading: isFileDeleting,
      title: isDeleteFileFromFormType
        ? t('editSendInvite.file.delete.title')
        : t('file.delete.title'),
      submitLabel: t('file.delete.buttonLabel'),
      submitColor: 'danger',
      message: t('file.delete.message', {
        fileName: selectedFile.original_name,
      }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileDeleting, selectedFile]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: rem(24) }}>
      <Title>Add Follow-Up Requests</Title>
      <FeatureFlags restriction="load_template_in_follow_up">
        <LoadTemplate handleClick={() => store.setIsTemplatesOpen(true)} />
      </FeatureFlags>

      <RequestList>
        {requests.map((request, index) => (
          <InviteRequest
            container="follow-up"
            key={request.id}
            id={index}
            hasDelete={requests.length > 1}
            isDeleteDisabled={isRequestDeleting}
          />
        ))}
      </RequestList>
    </Box>
  );
};

interface FollowUpRequest {
  requests: TInviteRequest[];
}

export const FollowUpRequests = React.memo(({ requests }: FollowUpRequest) => {
  const methods = useForm<TInviteRequestsForm>({
    defaultValues: defaultRequests,
    resolver: yupResolver(inviteRequestsSchema),
  });

  const { setActiveRequest } = useRequestsStore();

  useEffect(() => {
    if (!requests || !requests?.length) return;

    // convert data to form values
    /* if (data?.requests?.length === 1) {
      setActiveRequest(data.requests[0]);
    } */

    methods.reset({ requests });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requests, methods.reset]);

  return (
    <FormProvider {...methods}>
      <FollowUpRequestsContent />
    </FormProvider>
  );
});
