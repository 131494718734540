import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '@gen2/hooks';
import { useSidebarStore } from '@gen2/hooks/stores';
import { useMemo } from 'react';
import { MenuItem, team } from '../data';
import {
  StyledSidebarList,
  StyledSidebarListItem,
  StyledSidebarListItemLink,
  StyledSubMenuHeading,
} from '../sidebar.styled';

export const Team = () => {
  const store = useSidebarStore();
  const { featureFlags } = useAuth();

  const onItemClick = (item: MenuItem) => {
    store.setOpen(false);

    if (item.id === 'home') return; // dashboard has no submenu

    store.setSelected(item);
    store.setSubMenuOpen(false);
  };

  const displayTeam = useMemo(() => {
    return team.filter(
      (item) =>
        !item.featureFlag ||
        featureFlags?.[item.featureFlag as keyof typeof featureFlags],
    );
  }, [featureFlags]);

  return (
    <StyledSidebarList data-cy="sidebar-users">
      <StyledSubMenuHeading>TEAM</StyledSubMenuHeading>
      {displayTeam.map((item) => (
        <StyledSidebarListItem key={item.title}>
          <StyledSidebarListItemLink
            onClick={() => onItemClick(item)}
            to={item.to || ''}
            data-cy={`sidebar-users-link-${item?.title?.toLowerCase()}`}
          >
            <FontAwesomeIcon size="lg" icon={item.icon || 'x'} />
            <span>{item.title}</span>
          </StyledSidebarListItemLink>
        </StyledSidebarListItem>
      ))}
    </StyledSidebarList>
  );
};
