import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Templates } from '@gen2/app/invites/send-invites/templates/templates';
import {
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Container } from './load-template.styles';

export type TUpgradeModalProps = {
  handleClick: () => void;
};

const LoadTemplate = ({
  handleClick,
}: TUpgradeModalProps) => {
  const { t } = useTranslation('templates');

  return (
    <>
      <Container>
        <FontAwesomeIcon icon={solid('arrow-down')} />
        <span>{t('loadTemplate.label')}</span>
        <Button
          onClick={handleClick}
          size="small"
          color="tertiary"
          variant="outlined"
        >
          {t('loadTemplate.buttons.load')}
        </Button>
      </Container>
      <Templates />
    </>
  );
};

export default LoadTemplate;
