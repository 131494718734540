/* eslint-disable @typescript-eslint/no-explicit-any */
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LiveFormType } from '@gen2/app/invites/send-invites/store';
import { StyledFormTitle } from '@gen2/app/team-member/layout/team-member-create.styled';
import {
  Box,
  Divider,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnnotationTypeEnum, TAssignee } from '../utils/types';
import { DraggableAnnotation } from './draggableAnnotations/draggableAnnotations';

interface SidePanelProps {
  users: TAssignee[];
  handleChange?: (e: any) => void;
  currSignee: TAssignee;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, type: string) => void;
  onDragEnd: (e: any) => void;
  isTemplate?: boolean;
  type: LiveFormType;
}

const SidePanel = ({
  users,
  handleChange,
  currSignee,
  onDragStart,
  onDragEnd,
  isTemplate = false,
  type,
}: SidePanelProps) => {
  const { t } = useTranslation();

  return (
    <Box paddingX={2} paddingTop={2}>
      <Box>
        <StyledFormTitle style={{ paddingBottom: '10px', paddingTop: '10px' }}>
          Apply Fields
        </StyledFormTitle>
        <Typography variant="body2" paddingBottom={2}>
          Drag & drop fields on the document
        </Typography>
        <Divider></Divider>
        <DraggableAnnotation
          type={AnnotationTypeEnum.TextField}
          label="Text Field"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <DraggableAnnotation
          type={AnnotationTypeEnum.CheckBox}
          label="Check Box"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <DraggableAnnotation
          type={AnnotationTypeEnum.RadioButton}
          label="Radio Button"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
        <Divider></Divider>
        <StyledFormTitle style={{ paddingBottom: '10px', paddingTop: '10px' }}>
          Signature fields
          {isTemplate && type === 'multi' && (
            <Tooltip
              placement="right"
              title={t('previewTemplateForm.signatureTooltip', {
                ns: 'templates',
              })}
            >
              <FontAwesomeIcon
                icon={solid('info-circle')}
                size="lg"
                transform="right-5"
              />
            </Tooltip>
          )}
        </StyledFormTitle>
        <DraggableAnnotation
          type={AnnotationTypeEnum.SIGNATURE}
          label="Signature"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          isDraggable={!(isTemplate && type === 'multi')}
        />
        <DraggableAnnotation
          type={AnnotationTypeEnum.INITIAL}
          label="Initial"
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          isDraggable={!(isTemplate && type === 'multi')}
        />
        {type === 'multi' && handleChange && (
          <Box paddingY={2}>
            <TextField
              id="assignee-select"
              inputProps={{
                'data-cy': 'assignee-select',
              }}
              select
              label="Adding signature for"
              value={currSignee.email}
              fullWidth
              onChange={handleChange}
            >
              {users.map((option: TAssignee) => (
                <MenuItem key={option.id} value={option.email}>
                  {`${option.first_name} ${option.last_name}`}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SidePanel;
