import { useMutation, useQuery } from '@tanstack/react-query';
import { has } from 'lodash';
import { deleteFiles, getFileUrl, getPreviewUrl, TGetFileUrlQueryParams, TGetFileUrlRequest, uploadFile } from './api';

export enum FileKeys {
  UPLOAD_FILE = 'UPLOAD_FILE',
  DELETE_FILES = 'DELETE_FILE',
  GET_FILE_URL = 'GET_FILE_URL',
  GET_PREVIEW_URL = 'GET_PREVIEW_URL',
}

export const useUploadFileMutation = () =>
  useMutation({
    mutationKey: [FileKeys.UPLOAD_FILE],
    mutationFn: uploadFile,
  });

export const useDeleteFilesMutation = () =>
  useMutation({
    mutationKey: [FileKeys.DELETE_FILES],
    mutationFn: deleteFiles,
  });

export type TGetFileUrlQuery = TGetFileUrlRequest & {
  open: boolean;
};

export const useGetFileUrlQuery = ({ fileId }: TGetFileUrlQueryParams) =>
  useQuery({
    queryKey: [FileKeys.GET_FILE_URL, [fileId]],
    queryFn: async () => {
      try {
        const { data } = await getFileUrl({ fileId });
        if (data && has(data, 'data') && has(data.data, 'signed_url')) {
          return data.data.signed_url;
        }
        return null;
      } catch (error) {
        return null;
      }
    },
  });

export const useGetPreviewUrlQuery = ({ fileId, open }: TGetFileUrlQuery) =>
  useQuery({
    queryKey: [FileKeys.GET_PREVIEW_URL, [fileId]],
    queryFn: async () => {
      const { data } = await getPreviewUrl({ fileId });
      if (data && has(data, 'data') && has(data.data, 'preview_url')) {
        return data.data;
      }
      return null;
    },
    enabled: !!open,
  });