import { useMutation, useQuery } from '@tanstack/react-query';
import { has } from 'lodash';
import {
  saveLiveForm,
  getLiveForm
} from './api';

export enum LiveFormKeys {
  getLiveForm = 'getLiveForm',
}

export type TLiveForm = {
  liveform: {
    fields: {
      xfdf_data: string;
      json_data: string;
    }
  }
}

export const useSaveLiveFormMutation = () => useMutation({ mutationFn: saveLiveForm });

export const useGetLiveFormQuery = (fileId: string) =>
  useQuery({
    queryKey: [LiveFormKeys.getLiveForm, fileId],
    queryFn: async () => {
      const { data } = await getLiveForm(fileId);

      if (data && has(data, 'data')) {
        return data.data as TLiveForm;
      }
      return null;
    },
    enabled: !!fileId,
    cacheTime: 0,
  });

