import { create } from 'zustand';
export interface ILiveFormState {
  isCreateFormModalOpen: boolean;
  isWebViewerModalOpen: boolean;
  isNutrientViewerModalOpen: boolean;
  isNutrient: boolean;
}

export interface ILiveFormStore extends ILiveFormState {
  setIsCreateFormModalOpen: (open: boolean) => void;
  setIsWebViewerModalOpen: (open: boolean) => void;
  setIsNutrientViewerModalOpen: (open: boolean) => void;
  setIsNutrient: (isNutrient: boolean) => void;
  reset: () => void;
}

export const useLiveFormStore = create<ILiveFormStore>()((set) => ({
  isCreateFormModalOpen: false,
  setIsCreateFormModalOpen: (isCreateFormModalOpen: boolean) => set({ isCreateFormModalOpen }),
  isWebViewerModalOpen: false,
  setIsWebViewerModalOpen: (isWebViewerModalOpen: boolean) => set({ isWebViewerModalOpen }),
  isNutrientViewerModalOpen: false,
  setIsNutrientViewerModalOpen: (isNutrientViewerModalOpen: boolean) =>
    set({ isNutrientViewerModalOpen }),
  isNutrient: false,
  setIsNutrient: (isNutrient: boolean) => set({ isNutrient }),
  reset: () => {
    return set({
      isCreateFormModalOpen: false,
      isWebViewerModalOpen: false,
      isNutrientViewerModalOpen: false,
      isNutrient: false,
    });
  },
}));
