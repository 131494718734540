import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';
import {
  StyledTagsContainer,
  StyledTagsLineLabel,
} from './tags-dropdown.styles';

export interface TagsDropdownProps {
  label?: string | React.ReactNode;
  'data-cy'?: string;
  error?: string | React.ReactNode;
  tooltipTitle?: string;
  dropDownContent: ReactNode;
  icon?: IconProp;
}

export function TagsDropdown({
  label,
  'data-cy': dataCy,
  error = '',
  tooltipTitle = '',
  dropDownContent,
  icon,
  ...rest
}: TagsDropdownProps) {
  return (
    <StyledTagsContainer isError={Boolean(error)} data-cy={dataCy} {...rest}>
      {label && (
        <StyledTagsLineLabel isError={Boolean(error)}>
          {Boolean(error) && <span data-cy="asterisk">*</span>}
          {label}:
        </StyledTagsLineLabel>
      )}
      <Tooltip placement="right" title={tooltipTitle}>
        <FontAwesomeIcon icon={icon ? icon : solid('info-circle')} size="lg" />
      </Tooltip>
      {dropDownContent}
    </StyledTagsContainer>
  );
}

export default TagsDropdown;
