import { baseType, colors } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Theme, css, styled } from '@mui/material';
import { rem } from 'polished';
import { NavLink } from 'react-router-dom';

export const StyledSidebarList = styled('ul')({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  overflow: 'hidden',
});

export const CSSStyledSidebarListItem = ({ theme }: { theme: Theme; }) =>
  css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: baseType,
    color: 'inherit',
    gap: rem(15),
    paddingTop: rem(15),
    paddingBottom: rem(15),
    paddingLeft: rem(18),
    paddingRight: rem(18),
    transition: 'transform 300ms linear',
    '&:hover': {
      transform: 'scale(1.1)',
      color: colors.primary,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      '&:hover': {
        transform: 'scale(1.1)',
        color: colors.primary,
      },
    },
  });

export const StyledSidebarListItemLink = styled(NavLink)(
  CSSStyledSidebarListItem,
);

export const StyledSidebarListItemButton = styled('button')(
  CSSStyledSidebarListItem,
);

export const StyledSidebar = styled('div')(({ theme }) => ({
  backgroundColor: colors.grayDark4,
  color: 'white',
  position: 'fixed',
  top: 'var(--sidebar-header-height)',
  left: 0,
  bottom: 'var(--footer-height)',
  zIndex: 'var(--sidebar-z)',
  minWidth: rem(220),
  height: 'calc(100% - var(--sidebar-header-height) - var(--footer-height))',
  paddingTop: rem(15),
  paddingBottom: rem(15),
  transform: 'translateX(-100%)',
  transition: 'transform 300ms linear',
  '&.open': {
    transform: 'translateX(0)',
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: 'unset',
    paddingTop: rem(20),
    paddingBottom: rem(20),
    transform: 'translateX(0)',
    [StyledSidebarListItemLink]: {
      justifyContent: 'center',
      '& > span': {
        display: 'none',
      },
    },
    [StyledSidebarListItemButton]: {
      justifyContent: 'center',
      '& > span': {
        display: 'none',
      },
    },
    [StyledSidebarList]: {
      width: rem(70),
    },
  },
}));

export const StyledSidebarListItem = styled('li')({
  willChange: 'transform',
});

export const StyledSidebarDivider = styled(Divider)(({ theme }) => ({
  borderColor: colors.grayDark3,
  marginTop: rem(15),
  marginBottom: rem(15),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const StyledSidebarMain = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

export const StyledSubMenu = styled('div')<{ $width: number; }>(
  ({ theme, $width }) => ({
    position: 'fixed',
    top: 'var(--sidebar-header-height)',
    bottom: 'var(--footer-height)',
    backgroundColor: 'white',
    minWidth: rem(200),
    height: 'calc(100% - var(--sidebar-header-height) - var(--footer-height))',
    borderRight: `${rem(1)} solid ${colors.grayLight2}`,
    transform: 'translateX(-100%)',
    transition: '300ms linear',
    transitionProperty: 'opacity, transform',
    zIndex: 'var(--sidebar-submenu-z)', // lower than sidebar
    '&.open': {
      transform: 'translateX(0)',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      paddingLeft: rem(70),
      transform: 'translateX(0)',
      marginLeft: $width * -1 + 90,
      transition: 'margin-left 300ms linear',
      '&.open': {
        marginLeft: 0,
      },
    },
  }),
);

export const StyledSubMenuClose = styled(IconButton)<{
  $xPos?: 'left' | 'right';
}>(({ $xPos }) => ({
  position: 'absolute',
  top: rem(30),
  [$xPos === 'left' ? 'left' : 'right']: rem(-15),
  width: rem(30),
  height: rem(30),
  backgroundColor: colors.grayDark4,
  color: 'white',
  display: 'flex',
  '&.hidden': {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: colors.grayDark3,
  },
}));

export const StyledSubMenuHeading = styled('div')({
  textTransform: 'uppercase',
  color: colors.grayDark1,
  width: '100%',
  paddingLeft: rem(20),
  paddingRight: rem(20),
  paddingTop: rem(30),
  paddingBottom: rem(10),
  borderBottom: `${rem(1)} solid ${colors.grayLight2}`,
});

export const StyledIcon = styled(FontAwesomeIcon)({
  '&:hover': {
    cursor: 'pointer',
  },
});
