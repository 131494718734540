export async function onPressDuplicate(
  annotation: any,
  PSPDFKit: any,
  instance: any,
) {
  // For the new annotation, we will copy the current one but
  // translate the annotation for 50px so that our users see the
  // duplicated annotation.
  const allFormFields = await instance.getFormFields();
  const formField = allFormFields.find(
    (field: any) => field.name === annotation.formFieldName,
  );

  const newInstantId = PSPDFKit.generateInstantId();
  const newBoundingBox = annotation.boundingBox
    .set('top', annotation.boundingBox.top + 50)
    .set('left', annotation.boundingBox.left + 50);
  // To make duplication work, we also need to remove the ID
  // of the annotation.
  const duplicatedAnnotation = annotation
    .set('id', newInstantId)
    .set('name', newInstantId)
    .set('boundingBox', newBoundingBox)
    .set('formFieldName', newInstantId);
  // Updating formField with new options
  const randNum = Math.floor(Math.random() * 100);
  const newFormField = formField
    .set('annotationIds', new PSPDFKit.Immutable.List([newInstantId]))
    .set('id', newInstantId)
    .set('name', newInstantId);
  // In the end, we just use `createAnnotation` on our
  // PSPDFKit instance.
  //debugger;
  instance.create([duplicatedAnnotation, newFormField]);
}
