import { TTemplate } from '@gen2/api/templates/api';
import { noop } from 'lodash';
import { create, StoreApi } from 'zustand';

type TMergeTemplateModalPayload = {
  readyToSend?: boolean;
};

export type TMergeTemplateModalParams = {
  templates: TTemplate[];
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  selectedRequests?: string[],
  perTemplateSelectedCount?: {[key: string]: number;}
  requests?: {[key: string]: string[];}
  subject?: string;
  message?: string;
  reminderSettings?: string;
};

export interface IMergeTemplateModalStore extends TMergeTemplateModalParams {
  isModalOpen: boolean;
  submitHandler: (overrideIsSubmitting?: boolean) => Promise<boolean>;
  closeModal: (isModalOpen: boolean) => void;
  submitModal: () => void;
  showModal: (params: TMergeTemplateModalParams) => Promise<TMergeTemplateModalPayload>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;
  setAllRequestIds: (requests: {[key: string]: string[]}) => void;
  setSelectedRequestIds: (requests: string[]) => void;
  setPerTemplateSelectedCount: (count: {[key: string]: number;}) => void;
  setSubject(subject: string): void;
  setMessage(message: string): void;
  setReminderSettings(reminderSettings: string): void;
  setSubmitHandler(submitHandler: (overrideIsSubmitting?: boolean) => Promise<boolean>): void;
  appendSelectedRequestIds: (requests: string[]) => void;
  reset: () => void;
}

export const defaultActionModalState: Omit<IMergeTemplateModalStore, 'showModal'> = {
  isModalOpen: false,
  isSubmitting: false,
  isSubmitted: false,
  templates: [],
  requests: {},
  selectedRequests: [],
  perTemplateSelectedCount: {},
  subject: '',
  message: '',
  reminderSettings: '',
  submitHandler: async () => false,
  closeModal: noop,
  submitModal: noop,
  reset: noop,
  setIsSubmitting: noop,
  setIsSubmitted: noop,
  setAllRequestIds: noop,
  appendSelectedRequestIds: noop,
  setPerTemplateSelectedCount: noop,
  setSelectedRequestIds: noop,
  setSubject: noop,
  setMessage: noop,
  setReminderSettings: noop,
  setSubmitHandler: noop,
};

export const useMergeTemplateModalStore = create<IMergeTemplateModalStore>()(
  (set) => ({
    ...defaultActionModalState,
    showModal: async (params: TMergeTemplateModalParams) => {
      const res = await new Promise((resolve) => {
        setModalState(set, params, resolve);
      });

      return res as TMergeTemplateModalPayload;
    },
  })
); 

const mergeRequestIds = (existingRequestIds: string[], incomingRequestIds: string[]): string[] => {
  return [
    ...existingRequestIds,
    ...incomingRequestIds
  ].filter((reqId, index, self) => self.indexOf(reqId) === index);
};

const updateRequests = (prevRequests: { [key: string]: string[] }, newRequests: { [key: string]: string[] }): { [key: string]: string[] } => {
  const updatedRequests = { ...prevRequests };

  Object.keys(newRequests).forEach((key: string) => {
    const existingRequestIds = updatedRequests[key] || [];
    const incomingRequestIds = newRequests[key] || [];
    updatedRequests[key] = mergeRequestIds(existingRequestIds, incomingRequestIds);
  });

  return updatedRequests;
};

const setModalState = (
  set: StoreApi<IMergeTemplateModalStore>['setState'], 
  params: TMergeTemplateModalParams, 
  resolve: (value: {readyToSend: boolean}) => void
) => {
  set({
    templates: params.templates,
    isModalOpen: true,
    closeModal: () => {
      set({isModalOpen: false});
      resolve({readyToSend: false});
    },
    submitModal: () => {
      set({isModalOpen: false});
      resolve({readyToSend: true});
    },
    // actions
    setIsSubmitting: (isSubmitting: boolean ) => set({isSubmitting}),
    setIsSubmitted: (isSubmitted: boolean ) => set({isSubmitted}),
    setAllRequestIds: (requests: {[key: string]: string[]} ) => set((prevState) => {
      const prevRequest = prevState.requests || {};

      const updatedRequests = updateRequests(prevRequest, requests);

      return {
        ...prevState,
        requests: updatedRequests,
      };
    }),
    setSelectedRequestIds: (requests: string[] ) => set({selectedRequests: requests}),
    appendSelectedRequestIds: (requests: string[] ) => set((prevState) => {
      const newRequestIds = [
        ...prevState.selectedRequests || [],
        ...requests
      ];

      return {
        ...prevState,
        selectedRequests: newRequestIds.filter((reqId, index, self) => self.indexOf(reqId) === index),
      };
    }),
    setPerTemplateSelectedCount: (count:  {[key: string]: number;} ) => set((prevState) => {
      return {
        ...prevState,
        perTemplateSelectedCount: {
          ...prevState.perTemplateSelectedCount,
          ...count
        },
      };
    }),
    setSubject: (subject: string) => set({subject}),
    setMessage: (message: string) => set({message}),
    setReminderSettings: (reminderSettings: string) => set({reminderSettings}),
    setSubmitHandler: (submitHandler: (overrideIsSubmitting?: boolean) => Promise<boolean>) => set({submitHandler}),
    reset: () => set({...defaultActionModalState}),
  });
}
