import { colors, textSizes, weights } from '@components/theme/gen2';
import { Avatar, AvatarProps, Box, BoxProps, styled } from '@mui/material';
import { rem } from 'polished';

interface ImageAvatarProps extends BoxProps {
  imageurl: string;
}

export const ImageAvatar = styled(Box)<ImageAvatarProps>(({ imageurl }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  backgroundImage: `url(${imageurl})`,
  backgroundSize: 'cover',
  borderRadius: rem(20),
  backgroundPosition: 'center',
}));

export const ImageIconAvatar = styled('img')(() => ({
  display: 'flex',
  width: rem(28),
  height: rem(28),
  borderRadius: '50%',
}));

export const TextAvatar = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: rem(8),
});

export const AvatarBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: colors.grayDark3,
  gap: rem(8),
});

export const DetailsBox = styled('div')({
  '> span': {
    display: 'block',
    fontSize: rem(12),
    color: colors.grayDark2,
  },
});

export const StringAvatarBox = styled(Avatar)({
  width: rem(28),
  height: rem(28),
  background: colors.white,
  color: colors.grayDark2,
  fontSize: rem(14),
  fontWeight: weights.normal,
  textTransform: 'uppercase',
  '&.MuiAvatar-root': {
    border: `1px solid ${colors.grayDark2}`,
  },
});

interface StyledContactAvatarProps extends AvatarProps {
  size?: number;
}

export const StyledContactAvatar = styled(Avatar)<StyledContactAvatarProps>(
  ({ size }) => ({
    width: size ? rem(size) : rem(28),
    height: size ? rem(size) : rem(28),
    fontSize: size ? rem(textSizes.xl) : rem(textSizes.sm),
    backgroundColor: 'transparent',
    color: colors.grayDark2,
    border: `${rem(1)} solid ${colors.grayDark2}`,
  }),
);
