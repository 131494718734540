export enum PermissionsKeys {
  PRE_QUALIFICATION = 'pre_qualification',
  CREATE_INVITE = 'create_invite',
  UPDATE_INVITE = 'update_invite',
  SHOW_INVITE = 'show_invite',
  DELETE_DRAFT_INVITE = 'delete_draft_invite',
  LIST_INVITE = 'list_invite',
  SEND_INVITE = 'send_invite',
  RESEND_INVITE = 'resend_invite',
  SEND_EMAIL_PREVIEW_INVITE = 'send_email_preview_invite',
  ARCHIVE_INVITE = 'archive_invite',
  DELETE_ARCHIVE_INVITE = 'delete_archive_invite',
  MERGE_TEMPLATE_INVITE = 'merge_template_invite',
  CREATE_CONTACT_INVITE = 'create_contact_invite',
  DELETE_CONTACT_INVITE = 'delete_contact_invite',
  SHOW_CONTACT_GROUP = 'show_contact_group',
  DELETE_CONTACT_GROUP = 'delete_contact_group',
  UPDATE_CONTACT_GROUP = 'update_contact_group',
  LIST_CONTACT_GROUP = 'list_contact_group',
  LIST_CONTACT_GROUPS = 'list_contact_groups',
  LIST_CONTACTS_IN_CONTACT_GROUP = 'list_contacts_in_contact_group',
  REMOVE_CONTACT_FROM_CONTACT_GROUP = 'remove_contact_from_contact_group',
  CREATE_CONTACT_GROUP = 'create_contact_group',
  CREATE_INVITE_REQUEST = 'create_invite_request',
  UPDATE_INVITE_REQUEST = 'update_invite_request',
  DELETE_INVITE_REQUEST = 'delete_invite_request',
  LIST_INVITE_REQUEST = 'list_invite_request',
  ACCEPT_INVITE_REQUEST = 'accept_invite_request',
  REVISION_INVITE_REQUEST = 'revision_invite_request',
  CREATE_INVITE_REQUEST_CONTACT = 'create_invite_request_contact',
  DELETE_INVITE_REQUEST_CONTACT = 'delete_invite_request_contact',
  CREATE_CONTACT = 'create_contact',
  UPDATE_CONTACT = 'update_contact',
  SHOW_CONTACT = 'show_contact',
  DELETE_CONTACT = 'delete_contact',
  LIST_CONTACT = 'list_contact',
  RESTORE_CONTACT = 'restore_contact',
  IMPORT_CONTACT = 'import_contact',
  LOAD_TEMPLATE = 'load_template',
  UPLOAD_FILE = 'upload_file',
  DELETE_FILE = 'delete_file',
  PREVIEW_FILE = 'preview_file',
  DOWNLOAD_FILE = 'download_file',
  DOWNLOAD_ZIP_FILE = 'download_zip_file',
  FILE_SIGNED_URL = 'file_signed_url',
  CREATE_COLLATE = 'create_collate',
  LIST_COLLATE = 'list_collate',
  LIST_COLLATE_HISTORY = 'list_collate_history',
  CREATE_LIVEFORM_FIELD = 'create_liveform_field',
  SHOW_LIVEFORM_FIELD = 'show_liveform_field',
  DETECT_LIVEFORM_FIELD = 'detect_liveform_field',
  CREATE_LIVEFORM_VALUE = 'create_liveform_value',
  SHOW_LIVEFORM_VALUE = 'show_liveform_value',
  DELETE_LIVEFORM_VALUE = 'delete_liveform_value',
  CREATE_FOLLOW_UP = 'create_follow_up',
  UPDATE_FOLLOW_UP = 'update_follow_up',
  SHOW_FOLLOW_UP = 'show_follow_up',
  LIST_FOLLOW_UP = 'list_follow_up',
  SEND_FOLLOW_UP = 'send_follow_up',
  CREATE_TEMPLATE = 'create_template',
  UPDATE_TEMPLATE = 'update_template',
  RENAME_TEMPLATE = 'rename_template',
  SHOW_TEMPLATE = 'show_template',
  DELETE_TEMPLATE = 'delete_template',
  LIST_TEMPLATE = 'list_template',
  DUPLICATE_TEMPLATE = 'duplicate_template',
  LIST_TEMPLATE_REQUEST = 'list_template_request',
  CREATE_TEMPLATE_REQUEST = 'create_template_request',
  UPDATE_TEMPLATE_REQUEST = 'update_template_request',
  DELETE_TEMPLATE_REQUEST = 'delete_template_request',
  CREATE_BRANDING = 'create_branding',
  UPDATE_BRANDING = 'update_branding',
  LIST_BRANDING = 'list_branding',
  CREATE_NAMING_CONVENTION = 'create_naming_convention',
  UPDATE_NAMING_CONVENTION = 'update_naming_convention',
  SHOW_NAMING_CONVENTION = 'show_naming_convention',
  LIST_NAMING_CONVENTION = 'list_naming_convention',
  CREATE_CUSTOM_DOMAIN = 'create_custom_domain',
  SHOW_CUSTOM_DOMAIN = 'show_custom_domain',
  DELETE_CUSTOM_DOMAIN = 'delete_custom_domain',
  TEST_EMAIL_CUSTOM_DOMAIN = 'test_email_custom_domain',
  TEST_SMTP_CUSTOM_DOMAIN = 'test_smtp_custom_domain',
  OMNI_SEARCH = 'omni_search',
  LIST_ROLE = 'list_role',
  CREATE_MESSAGE = 'create_message',
  LIST_MESSAGE = 'list_message',
  CREATE_USER = 'create_user',
  UPDATE_USER = 'update_user',
  SHOW_USER = 'show_user',
  LIST_USER = 'list_user',
  CREATE_TEAM = 'create_team',
  UPDATE_TEAM = 'update_team',
  SHOW_TEAM = 'show_team',
  LIST_TEAM = 'list_team',
  DELETE_TEAM = 'delete_team',
  LIST_TEAM_USER = 'list_team_user',
  DELETE_TEAM_USER = 'delete_team_user',
  CREATE_INVITE_CONTACT_REQUEST = 'create_invite_contact_request',
  DELETE_INVITE_CONTACT_REQUEST = 'delete_invite_contact_request',
  LIST_WATCHER = 'list_watcher',
  WATCH_INVITE = 'watch_invite',
  UNWATCH_INVITE = 'unwatch_invite',
}
