/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  StyledAvatar,
  StyledTooltipContainer,
  StyledTooltipDetails,
  StyledTooltipEmail,
  StyledTooltipName,
} from '@components/lib/avatar-tooltip/avatar-tooltip.styles';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { InviteRequestsKeys } from '@gen2/api/invite-requests/hooks';
import {
  InvitesKeys,
  useRemoveContactFromInviteMutation,
} from '@gen2/api/invites/hooks';
import { Keys } from '@gen2/api/team-member/hooks';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { StringAvatarBox } from '@gen2/app/components/avatar/avatar.styled';
import { queryClient } from '@gen2/config';
import { useAuth, useToast } from '@gen2/hooks';
import { TBasicUser } from '@gen2/types/user';
import { contactsToTags } from '@gen2/utils/contacts';
import { stringAvatar, stringInitialsAvatar } from '@gen2/utils/name';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { get } from 'lodash';
import { SyntheticEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useSendInvite } from './hooks';
import { MAX_CONTACT_LIMIT, MAX_SUBJECT_LENGTH } from './schema';
import { TDraftInviteForm } from './send-invites';
import {
  AlertContainer,
  Stickyhead,
  StyledActions,
  StyledAlert,
  StyledAlertButton,
  StyledDueDate,
  StyledHead,
  StyledPointOfContact,
  StyledReminder,
  StyledSendTo,
  Styledspan,
  StyledSubjectLine,
} from './send-invites.styled';
import { useSendInviteStore } from './store';
import { usePointOfContactAutocomplete } from './usePointOfContactAutocomplete';
dayjs.extend(utc);
dayjs.extend(timezone);

type TInviteHeadProps = {
  almostDueAnchor: React.RefObject<HTMLButtonElement>;
};

export const createTooltip = (avatar: string, name: string, email: string) => {
  return (
    <StyledTooltipContainer>
      <StyledAvatar data-cy="header-avatar-btn">{avatar}</StyledAvatar>
      <StyledTooltipDetails>
        <StyledTooltipName>{name}</StyledTooltipName>
        <StyledTooltipEmail>{email}</StyledTooltipEmail>
      </StyledTooltipDetails>
    </StyledTooltipContainer>
  );
};

const InviteHead = ({ almostDueAnchor }: TInviteHeadProps) => {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<TDraftInviteForm>();
  const store = useSendInviteStore();
  // const router = useRouter();
  const params = useParams<{ id: string; }>();
  // for new invite draft & updating invite both
  const isInviteIdReady = !!store.contextInviteIdForNewInvite || params.id;
  const { t } = useTranslation('sendInvite');
  // const sidebarStore = useSidebarStore();
  const { user } = useAuth();
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up('sm'));
  const { mutateAsync: removeContactFromInviteMutation } =
    useRemoveContactFromInviteMutation();
  const toast = useToast();

  const {
    mixedPointofContacts,
    isPointofContactsLoading,
    selectedPointOfContact,
    setSelectedPointOfContact,
    setSearch,
    showAvatar,
    setShowAvatar,
    isSearching,
    setIsSearching,
  } = usePointOfContactAutocomplete();

  const { onSave, onSend, isSending } = useSendInvite();

  const { showModal } = useActionModalStore();

  // registers
  const rSubject = register('subject');
  const rDueDate = register('due_at');

  // watches
  const wSubject = watch('subject');
  const wDueDate = watch('due_at');
  const wContacts = watch('contacts');
  const wAlmostDue = watch('almost_due_reminder');
  const wCustomAlmostDue = watch('customAlmostDueValue');

  // https://fileinvite.atlassian.net/browse/FG2-3534
  // Subject field should have empty value and only has hint text which is “Enter a subject line”
  useEffect(() => {
    if (wSubject === 'Sample Subject') {
      setValue('subject', '');
    }
  }, [setValue, wSubject]);

  // timezone setting
  const orgTimezone = get(user, 'organisations[0].timezone');
  const minDate = dayjs().tz(orgTimezone).add(1, 'day').startOf('day');
  const maxDate = dayjs()
    .tz(orgTimezone)
    .add(3, 'months')
    .endOf('day')
    .subtract(1, 'day');

  // const onOpenInviteSettings = () => {
  //   sidebarStore.switchSubmenu({
  //     id: 'inviteSettings',
  //     title: 'inviteSettings',
  //     to: '/',
  //   });
  // };

  const onSendToDelete = async (id: string) => {
    if (!wContacts) return;

    store.set({ isSendFromLoading: true });

    const deleteContact = async () => {
      try {
        await removeContactFromInviteMutation({
          inviteId: store.invite.id,
          contactId: id,
        });

        setValue(
          'contacts',
          wContacts.filter((contact) => contact.id !== id),
        );

        await queryClient.invalidateQueries([InvitesKeys.getInvite]);
        await queryClient.invalidateQueries([
          InviteRequestsKeys.getInviteRequests,
        ]);
        await queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);

        const message =
          store.invite.status === 'draft'
            ? t('editSendInvite.removeContactFromDraftInvite.success')
            : t('editSendInvite.removeContactFromEditInvite.success');

        store.setBannerMessage({
          severity: 'info',
          message: message,
        });
      } catch (err) {
        const error = err as AxiosResponse;

        if (error.status === 422) {
          showModal({
            header: t('editSendInvite.removeContactFromEditInvite.error.title'),
            translationNamespace: 'sendInvite',
            message: 'editSendInvite.removeContactFromEditInvite.error.message',
            closeButtonLabel:
              t('editSendInvite.requestContact.notAssigned.close', {
                ns: 'sendInvite',
              }) ?? '',
          });
        } else {
          store.setBannerMessage({
            severity: 'error',
            message: 'Error removing contact',
          });

          console.error('Error removing contact:', error);
        }
      } finally {
        store.set({ isSendFromLoading: false });
      }
    };

    if (store.isEditSendInvite) {
      const contactToDelete = wContacts.find((contact) => contact.id === id);

      if (wContacts.length === 1) {
        const { isConfirmed } = await showModal({
          header: t('editSendInvite.deleteContact.atLeastOneContact.title'),
          message: 'editSendInvite.deleteContact.atLeastOneContact.message',
          closeButtonLabel: t('editSendInvite.deleteContact.close') ?? '',
        });
        if (!isConfirmed) {
          store.set({ isSendFromLoading: false });
        }
      } else {
        const { isConfirmed } = await showModal({
          header: t('editSendInvite.deleteContact.delete.title') ?? '',
          message: 'editSendInvite.deleteContact.delete.message',
          messageParams: {
            firstname: `${contactToDelete?.first_name}`,
            lastname: `${contactToDelete?.last_name}`,
          },
          closeButtonLabel: t('editSendInvite.deleteContact.cancel') ?? '',
          submitButtonLabel: t('editSendInvite.deleteContact.confirm') ?? '',
        });

        if (isConfirmed) {
          await deleteContact();
        } else {
          store.set({ isSendFromLoading: false });
        }
      }
    } else {
      if (!store.invite.id) {
        setValue(
          'contacts',
          wContacts.filter((contact) => contact.id !== id),
        );
        store.set({ isSendFromLoading: false });
      }
      await deleteContact();
    }
  };

  const onSendToClick = (event: React.MouseEvent<HTMLElement>) => {
    //clear contacts error status
    clearErrors('contacts');

    if ((wContacts?.length || 0) >= MAX_CONTACT_LIMIT) {
      store.setUpgradeModal({
        isOpen: true,
        title: t('contacts.upgrade.title') ?? '',
        description: t('contacts.upgrade.description') ?? '',
      });

      return;
    }

    store.setContactPopupAnchorEl(event.currentTarget);
    store.setIsContactListOpen(true);
  };

  const onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 100) {
      return;
    }

    setValue('subject', event.target.value);

    clearErrors('subject');
    rSubject.onChange(event);
  };

  const onDueDateChange = (date: Dayjs | null) => {
    // toggle due date
    if (date && wDueDate) {
      if (date.isSame(wDueDate, 'day')) {
        setValue('due_at', null);
        onSave();
        return;
      }
    }

    // if due date is greater than max almost due then default it to "never" or reset if custom
    if (date) {
      const newMaxAlmostDue = date.day() - dayjs().day();

      if (
        newMaxAlmostDue <= Number(wAlmostDue) ||
        newMaxAlmostDue <= Number(wCustomAlmostDue)
      ) {
        setValue('almost_due_reminder', '0');
        setValue('customAlmostDueValue', '');
      }
    }

    setValue('due_at', date);
    onSave();
  };

  const openReminder = () => {
    store.setIsReminderOpen(true);
  };

  const handleFinishEdit = () => {
    toast.show({
      text: t('editSendInvite.finishEdit'),
      variant: 'success',
    });
  };

  const handleChange = async (
    event: SyntheticEvent<Element, Event>,
    newValue: TBasicUser | null,
    reason: string,
  ) => {
    if (!newValue || reason !== 'selectOption') return;

    const updatePointOfContact = async () => {
      setSelectedPointOfContact([newValue]);
      setShowAvatar(true);
      clearErrors('from_user_id');
      setValue('from_user_id', newValue.id);
      onSave();
    };

    if (store.isEditSendInvite) {
      const { isConfirmed } = await showModal({
        header: t('editSendInvite.updatePointofContact.title'),
        translationNamespace: 'sendInvite',
        message: 'editSendInvite.updatePointofContact.message',
        closeButtonLabel: t('editSendInvite.updatePointofContact.cancel') ?? '',
        submitButtonLabel:
          t('editSendInvite.updatePointofContact.confirm') ?? '',
        submitButtonColor: 'secondary',
      });
      if (isConfirmed) {
        await updatePointOfContact();
        // refresh the user list in case the selected point of contact is demoted
        await queryClient.invalidateQueries([Keys.queryTeamMemberList]);
      }
    } else {
      await updatePointOfContact();
      // refresh the user list in case the selected point of contact is demoted
      await queryClient.invalidateQueries([Keys.queryTeamMemberList]);
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<object>,
    newInputValue: string,
    reason: string,
  ) => {
    clearErrors('from_user_id');
    setShowAvatar(false);
    if (reason === 'input') {
      setSearch(newInputValue);
      setIsSearching(true);
    } else if (reason === 'clear') {
      setSearch('');
      setSelectedPointOfContact([]);
      setValue('from_user_id', '');
      onSave();
    } else if (reason === 'reset') {
      setShowAvatar(true);
    }
  };

  // ui optiomization for text due to Debouncing Delay
  // shows noOptionsText before the search results are available
  useEffect(() => {
    if (!isPointofContactsLoading) {
      setIsSearching(false);
    }
  }, [isPointofContactsLoading, setIsSearching]);

  return (
    <div>
      <Stickyhead
        data-cy="stick-head"
        isEditSendInvite={store.isEditSendInvite}
      >
        <StyledActions>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              backgroundColor: 'red',
            }}
          >
            {store.isEditSendInvite ? (
              <AlertContainer>
                <StyledAlert icon={false} color="warning" severity="warning">
                  {t('editSendInvite.editBanner.alert')}
                </StyledAlert>
                <Link to={`/invite-listing/${store.invite.id}`}>
                  <StyledAlertButton
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleFinishEdit}
                  >
                    {t('editSendInvite.editBanner.button')}
                  </StyledAlertButton>
                </Link>
              </AlertContainer>
            ) : (
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  data-cy="si-send-btn"
                  onClick={onSend}
                  loading={isSending}
                >
                  {tablet ? (
                    'Send Invite'
                  ) : (
                    <FontAwesomeIcon icon={regular('send')} />
                  )}
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </StyledActions>
      </Stickyhead>
      <StyledHead $opaque={!isInviteIdReady}>
        <StyledSendTo
          label="Send to"
          data-cy="si-send-to-label"
          onDeleteTag={onSendToDelete}
          onLabelClick={onSendToClick}
          tags={contactsToTags(wContacts)}
          error={errors.contacts?.message && t(errors.contacts?.message)}
          isLoading={store.isSendFromLoading}
          disabled={store.isSendFromLoading}
          hiddenButton={false}
        />

        <StyledSubjectLine
          name={rSubject.name}
          value={wSubject}
          data-cy="si-subject-line"
          placeholder="Enter a subject line"
          label="Subject"
          error={errors.subject?.message && t(errors.subject?.message)}
          inputProps={{
            ...rSubject,
            onChange: onSubjectChange,
            onBlur: onSave,
          }}
          maxLength={MAX_SUBJECT_LENGTH}
          required
        />

        <StyledPointOfContact
          data-cy="si-point-of-contact"
          label={t('pointOfContact.label')}
          tooltipTitle={t('pointOfContact.tooltip') ?? ''}
          error={errors.from_user_id?.message}
          dropDownContent={
            <Autocomplete
              id="point-of-contact-list"
              loading={isPointofContactsLoading}
              options={mixedPointofContacts ?? null}
              sx={{ width: 316, lg: { width: 450 } }}
              getOptionLabel={(option: TBasicUser) =>
                `${option.first_name} ${option.last_name}`
              }
              noOptionsText={
                isPointofContactsLoading || isSearching
                  ? t('pointOfContact.searchingPlaceholder')
                  : t('pointOfContact.noOptionsText')
              }
              popupIcon={<FontAwesomeIcon icon={regular('magnifying-glass')} />}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props: any, option: TBasicUser) => {
                const { key, ...optionProps } = props;
                return (
                  <div key={key} {...optionProps}>
                    <StringAvatarBox src={option.avatar_url} sx={{ mr: 1 }}>
                      {!option.avatar_url
                        ? stringAvatar(option.first_name, option.last_name)
                        : null}
                    </StringAvatarBox>
                    <Styledspan>
                      {option.first_name} {option.last_name}{' '}
                      {option?.isMyself ? `(Myself)` : null}
                    </Styledspan>
                  </div>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t('pointOfContact.searchingPlaceholder') ?? ''}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        {showAvatar ? (
                          <StringAvatarBox
                            sx={{ mr: 1 }}
                            src={
                              mixedPointofContacts.find(
                                (opt) => opt.id === params.inputProps.value,
                              )?.avatar_url
                            }
                          >
                            {!mixedPointofContacts.find(
                              (opt) => opt.id === params.inputProps.value,
                            )?.avatar_url
                              ? stringInitialsAvatar(
                                params.inputProps.value as string,
                              )
                              : null}
                          </StringAvatarBox>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.from_user_id?.message}
                  helperText={
                    errors.from_user_id?.message &&
                    t(errors.from_user_id?.message)
                  }
                />
              )}
              value={selectedPointOfContact[0] ?? null}
              onChange={handleChange}
              onInputChange={handleSearchInputChange}
            />
          }
        />
        <StyledDueDate
          name={rDueDate.name}
          value={wDueDate}
          label="Due Date"
          disablePast
          minDate={minDate}
          maxDate={maxDate}
          error={errors.due_at?.message && t(errors.due_at?.message)}
          clearErrors={clearErrors}
          onChange={onDueDateChange}
          timezone={orgTimezone}
          tooltipTitle={t('due_date.tooltip')}
        />
        <StyledReminder>
          <span>{t('reminder.label')}</span>
          <Button
            size="small"
            variant="outlined"
            color="tertiary"
            ref={almostDueAnchor}
            data-cy="si-reminder-btn"
            onClick={openReminder}
            endIcon={<FontAwesomeIcon icon={solid('caret-down')} />}
          >
            {t('reminder.viewSettings')}
          </Button>
        </StyledReminder>
      </StyledHead>
    </div>
  );
};

export default InviteHead;
