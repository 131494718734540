

import { SetupPlugin } from '@gen2/app/components/rich-text-editor/plugins/SetupPlugin/SetupPlugin';
import RichTextEditor from '@gen2/app/components/rich-text-editor/rich-text-editor';
import { yupResolver } from '@hookform/resolvers/yup';
import { $generateHtmlFromNodes } from '@lexical/html';
import { Box, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { LexicalEditor } from 'lexical';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { MAX_MESSAGE_LENGTH } from '../../../schema';
import { useMergeTemplateModalStore } from '../store';
import {
  ErrorMessage,
  FieldContainer,
  FieldLabel,
  FieldWrapper,
  FormContainer,
  OtherSettingsContainer,
  OtherSettingsDescription,
  RemindersWrapper,
  StyledFormControl,
} from './other-settings.styled';

type OtherSettingsForm = {
  subject: string;
  message: string;
  meta: string;
  reminders: string;
};

const schema = Yup.object().shape({
  subject: Yup.string().required(
    'mergeModal.otherSettings.form.subject.required',
  ),
  message: Yup.string().required(
    'mergeModal.otherSettings.form.message.required',
  ),
  reminders: Yup.string().required(
    'mergeModal.otherSettings.form.reminders.required',
  ),
});

const defaultValues = {
  subject: '',
  message: '',
  reminders: '',
};

export const OtherSettings = () => {
  const { t } = useTranslation('templates');

  const {
    formState: { errors },
    register,
    watch,
    trigger,
    setValue,
  } = useForm<OtherSettingsForm>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const wSubjectTemplateId = watch('subject');
  const wMessageTemplateId = watch('message');
  const wRemindersTemplateId = watch('reminders');

  const {
    isSubmitting,
    templates,
    subject,
    message,
    reminderSettings,
    submitHandler,
    setIsSubmitting,
    setSubject,
    setMessage,
    setReminderSettings,
  } = useMergeTemplateModalStore();

  useEffect(() => {
    if (subject || message || reminderSettings) {
      setValue('subject', subject || '');
      setValue('message', message || '');
      setValue('reminders', reminderSettings || '');
    }
  }, [message, reminderSettings, setValue, subject]);

  useEffect(() => {
    const validateForm = async () => {
      if (isSubmitting) {
        const isValid = await trigger();

        if (!isValid) {
          setIsSubmitting(false);

          return;
        }

        await submitHandler();
      }
    };

    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, submitHandler]);

  /**
   * Subject line
   */
  const [subjectTextFieldValue, setSubjectTextFieldValue] =
    useState<string>('');

  useEffect(() => {
    if (wSubjectTemplateId) {
      trigger('subject');

      if (wSubjectTemplateId === 'no-template') {
        setSubjectTextFieldValue('');
        setSubject(wSubjectTemplateId);
        return;
      }

      const filteredTemplate = templates.find(
        (template) => template?.id === wSubjectTemplateId,
      );

      if (filteredTemplate) {
        setSubjectTextFieldValue(filteredTemplate.subject || '');
        setSubject(filteredTemplate.id || '');

        return;
      }

      setSubjectTextFieldValue('');
    }
  }, [setSubject, setValue, templates, trigger, wSubjectTemplateId]);

  /**
   * Message
   */
  const [messageTextFieldValue, setMessageTextFieldValue] = useState<{
    message: string;
    meta: string;
  }>({ message: '', meta: '' });

  useEffect(() => {
    if (wMessageTemplateId) {
      trigger('message');

      if (wMessageTemplateId === 'no-template') {
        setMessageTextFieldValue({ message: '', meta: '' });
        setMessage(wMessageTemplateId);
        return;
      }

      const filteredTemplate = templates.find(
        (template) => template?.id === wMessageTemplateId,
      );

      if (filteredTemplate) {
        setMessageTextFieldValue({
          message: filteredTemplate.message || '',
          meta: filteredTemplate.meta || '',
        });

        setMessage(filteredTemplate.id || '');

        return;
      }

      setMessageTextFieldValue({ message: '', meta: '' });
    }
  }, [setMessage, templates, trigger, wMessageTemplateId]);

  /**
   * Reminders
   */
  const [remindersSelectedValue, setRemindersSelectedValue] = useState<{
    almost_due_reminder: number;
    overdue_reminder: number;
  }>({ almost_due_reminder: 0, overdue_reminder: 0 });

  useEffect(() => {
    if (wRemindersTemplateId) {
      trigger('reminders');

      if (wRemindersTemplateId === 'no-template') {
        setRemindersSelectedValue({
          almost_due_reminder: 0,
          overdue_reminder: 0,
        });
        setReminderSettings(wRemindersTemplateId);
        return;
      }

      const filteredTemplate = templates.find(
        (template) => template?.id === wRemindersTemplateId,
      );

      if (filteredTemplate) {
        setRemindersSelectedValue({
          almost_due_reminder: filteredTemplate.almost_due_reminder || 0,
          overdue_reminder: filteredTemplate.overdue_reminder || 0,
        });

        setReminderSettings(filteredTemplate.id || '');

        return;
      }

      setRemindersSelectedValue({
        almost_due_reminder: 0,
        overdue_reminder: 0,
      });
    }
  }, [setReminderSettings, templates, trigger, wRemindersTemplateId]);

  const onEditorSetup = (editor: LexicalEditor) => {
    const editorState = editor.getEditorState();

    editorState.read(() => {
      const rawHTML = $generateHtmlFromNodes(editor);

      setMessageTextFieldValue((prev) => {
        return {
          ...prev,
          message: rawHTML,
        };
      });
    });
  };

  return (
    <OtherSettingsContainer>
      <Box>
        <OtherSettingsDescription>
          {t('mergeModal.otherSettings.description')}
        </OtherSettingsDescription>
      </Box>

      <FormContainer>
        <FieldContainer>
          <FieldLabel>Subject line:</FieldLabel>
          <FieldWrapper>
            <StyledFormControl fullWidth error={Boolean(errors.subject)}>
              <InputLabel id="subject-line">Select Subject Line</InputLabel>
              <Select
                data-cy="subject-line-menu"
                inputProps={{
                  'data-cy': 'subject-line',
                }}
                value={subject}
                fullWidth
                {...register('subject')}
                onChange={(item) => setSubject(item.target.value)}
                label="Select Subject Line"
              >
                <MenuItem value={''} sx={{ display: 'none' }} />

                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}

                <MenuItem value="no-template">
                  {t('mergeModal.otherSettings.noTemplate')}
                </MenuItem>
              </Select>
              {errors.subject && (
                <ErrorMessage data-cy="subject-error">
                  {errors.subject?.message && t(errors.subject.message)}
                </ErrorMessage>
              )}
            </StyledFormControl>
            <Box sx={{ width: '100%' }}>
              <TextField
                fullWidth
                id="subject-line-input"
                value={subjectTextFieldValue}
                label="Subject Line"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </FieldWrapper>
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Message:</FieldLabel>
          <FieldWrapper>
            <StyledFormControl fullWidth error={Boolean(errors.message)}>
              <InputLabel id="message">Select Message</InputLabel>
              <Select
                data-cy="message-menu"
                inputProps={{
                  'data-cy': 'message',
                }}
                value={message}
                fullWidth
                {...register('message')}
                onChange={(item) => setMessage(item.target.value)}
                label="Select Message"
              >
                <MenuItem value={''} sx={{ display: 'none' }} />

                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}

                <MenuItem value="no-template">
                  {t('mergeModal.otherSettings.noTemplate')}
                </MenuItem>
              </Select>
              {errors.message && (
                <ErrorMessage data-cy="message-error">
                  {errors.message?.message && t(errors.message.message)}
                </ErrorMessage>
              )}
            </StyledFormControl>

            <Box sx={{ width: '100%' }}>
              <RichTextEditor
                placeholder="Message"
                withToolbar={false}
                isEditable={false}
              >
                <SetupPlugin
                  onSetup={onEditorSetup}
                  maxLength={MAX_MESSAGE_LENGTH}
                  defaultEditorState={messageTextFieldValue?.meta}
                />
              </RichTextEditor>
            </Box>
          </FieldWrapper>
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Reminder Settings:</FieldLabel>
          <FieldWrapper>
            <StyledFormControl fullWidth error={Boolean(errors.reminders)}>
              <InputLabel id="reminder-settings">
                Select Reminder Settings
              </InputLabel>
              <Select
                data-cy="reminder-settings-menu"
                inputProps={{
                  'data-cy': 'reminder-settings',
                }}
                value={reminderSettings}
                fullWidth
                {...register('reminders')}
                onChange={(item) => setReminderSettings(item.target.value)}
                label="Select Reminder Settings"
              >
                <MenuItem value={''} sx={{ display: 'none' }} />

                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}

                <MenuItem value="no-template">
                  {t('mergeModal.otherSettings.noTemplate')}
                </MenuItem>
              </Select>
              {errors.reminders && (
                <ErrorMessage data-cy="reminders-error">
                  {errors.reminders?.message && t(errors.reminders.message)}
                </ErrorMessage>
              )}
            </StyledFormControl>

            <RemindersWrapper>
              <Box sx={{ width: '50%' }}>
                <span>
                  Almost due reminder:{' '}
                  {remindersSelectedValue.almost_due_reminder || '-'}
                </span>
              </Box>
              <Box sx={{ width: '50%' }}>
                <span>
                  Overdue reminder:{' '}
                  {remindersSelectedValue.overdue_reminder || '-'}
                </span>
              </Box>
            </RemindersWrapper>
          </FieldWrapper>
        </FieldContainer>
      </FormContainer>
    </OtherSettingsContainer>
  );
};
