/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useGetLiveFormQuery,
  useSaveLiveFormMutation,
} from '@gen2/api/live-form/hooks';
import { LiveFormType } from '@gen2/app/invites/send-invites/store';
import { useAuth, useToast } from '@gen2/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog } from '@mui/material';
import { AxiosError } from 'axios';
import { omit } from 'lodash';
import PSPDFKit, { Instance } from 'pspdfkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce, useUnmount } from 'usehooks-ts';
import { onDragStart } from './nutrient/sidePanel/helpers';
import SidePanel from './nutrient/sidePanel/sidePanel';
import {
  generateInstantJSON,
  handleAnnotationCreation,
  handleAnnotationDelete,
  TOOLBAR_ITEMS,
  updateAnnotationIdsBasedOnPdfObjectId,
} from './nutrient/utils/common-helpers';
import getAnnotationRenderers from './nutrient/utils/customAnnotationRenderer';
import customizeUIForInitials from './nutrient/utils/customizeUIForInitials';
import handleDrop from './nutrient/utils/dropAnnotations';
import { onPressDuplicate } from './nutrient/utils/helpers';
import { TAssignee } from './nutrient/utils/types';
import {
  HeaderTitle,
  StyledHeader,
  StyledHeaderActions,
  StyledViewer,
  StyledWebViewerBox,
} from './peview-web-viewer-modal.styled';
export interface FormViewerModalProps {
  fileId: string;
  fileName: string;
  onClose: () => void;
  open: boolean;
  fileUrl: string;
  type: LiveFormType;
}

const defaultAssignee = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
};

export const TemplateNutrientWebViewerModal = ({
  fileName,
  onClose,
  open,
  fileId,
  type,
  fileUrl,
}: FormViewerModalProps) => {
  const viewer = useRef(null);
  const { user } = useAuth();
  // const { t } = useTranslation();
  const liveFormType: LiveFormType = type;

  const contacts: TAssignee[] = [];

  const { mutate, isLoading } = useSaveLiveFormMutation();
  const toast = useToast();
  // Solution：solely to work around an issue that only appears in Development mode,
  // forcing WebViewer Initialization Only Occurs Once in Development build -- the Strict Mode
  const beenInitialised = useRef<boolean>(false);

  const [instance, setInstance] = useState<any>(null);

  const initialAssignee = defaultAssignee;

  const [currSignee, setCurrSignee] = useState(initialAssignee);
  const currSigneeRef = useRef(currSignee);
  currSigneeRef.current = currSignee;

  const [currUser] = useState<TAssignee>(user as TAssignee);
  const currUserRef = useRef(currUser);
  currUserRef.current = currUser;

  // State to store the current page index
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;
  const { data } = useGetLiveFormQuery(fileId);

  const instantJSON = useMemo(() => {
    if (data?.liveform?.fields?.json_data) {
      return JSON.parse(data.liveform.fields.json_data);
    }
    return '';
  }, [data]);

  // For Custom signature / initial field appearance
  const mySignatureIdsRef = useRef([]);
  const [signatureAnnotationIds, setSignatureAnnotationIds] = useState<
    string[]
  >([]);

  // For Custom Add Signature / Intitial field appearance
  const [sessionSignatures, setSessionSignatures] = useState<any>([]);
  const [sessionInitials, setSessionInitials] = useState<any>([]);

  function onDragEnd(event: React.DragEvent<HTMLDivElement>) {
    (event.target as HTMLDivElement).style.opacity = '1';
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const [isTextAnnotationMovable, setIsTextAnnotationMovable] = useState(false);
  const isTextAnnotationMovableRef = useRef(isTextAnnotationMovable);
  isTextAnnotationMovableRef.current = isTextAnnotationMovable;

  // Tracking whether add Signature/Initial UI
  let isCreateInitial = false;
  let trackInst: any = null;
  const loadPSPDFKit = async (el?: HTMLElement) => {
    if (PSPDFKit && fileUrl && el && !beenInitialised.current) {
      PSPDFKit.unload(el);
      beenInitialised.current = true;
      try {
        const {
          UI: { createBlock, Recipes, Interfaces },
        } = PSPDFKit;
        await PSPDFKit.load({
          container: el,
          document: fileUrl,
          // licenseKey: customDecode(window.$xr9c42),
          baseUrl: `${window.location.protocol}//${window.location.host}/assets/lib/pspdfkit/`,
          toolbarItems: [...TOOLBAR_ITEMS],
          disableTextSelection: false,
          initialViewState: new PSPDFKit.ViewState({
            interactionMode: PSPDFKit.InteractionMode.FORM_CREATOR,
          }),
          styleSheets: [`/assets/lib/pspdfkit/viewer.css`],
          isEditableAnnotation: (annotation) => !annotation.isSignature,
          ui: {
            [Interfaces.CreateSignature]: ({ props }) => ({
              content: createBlock(Recipes.CreateSignature, props, ({ ui }) => {
                if (isCreateInitial) ui = customizeUIForInitials(ui);
                return ui.createComponent();
              }).createComponent(),
            }),
          },
          customRenderers: {
            Annotation: ({ annotation }) => {
              return getAnnotationRenderers({ annotation });
            },
          },
        }).then((instance) => {
          trackInst = instance;
          setInstance(instance);
          configureInstanceEvents(instance); // Configure instance-specific events
        });
      } catch (error) {
        console.error('Error loading PSPDFKit:', error);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setTimeout(() => loadPSPDFKit(viewer.current!));
    }
  };

  const cleanupPSPDFKit = async () => {
    if (instance && viewer.current) {
      await PSPDFKit.unload(viewer.current);
      viewer.current = null;
      setInstance(null);
    }
  };

  useEffectOnce(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    loadPSPDFKit(viewer.current!);
  });

  useEffect(() => {
    const loadAnnotations = async () => {
      if (instance && instantJSON) {
        await instance.applyOperations([
          {
            type: 'applyInstantJson',
            instantJson: omit(instantJSON, ['pdfId']),
          },
        ]);
        // reset view state to form creator mode
        instance.setViewState((viewState: any) =>
          viewState.set(
            'interactionMode',
            PSPDFKit.InteractionMode.FORM_CREATOR,
          ),
        );
      }
    };
    loadAnnotations();
  }, [instance, instantJSON]);

  useUnmount(async () => {
    await cleanupPSPDFKit();
  });

  const configureInstanceEvents = (inst: Instance) => {
    // Additional configurations and event listeners for the PSPDFKit instance

    inst.setViewState((viewState) =>
      viewState.set(
        'showSignatureValidationStatus',
        PSPDFKit.ShowSignatureValidationStatusMode.IF_SIGNED,
      ),
    );

    inst.addEventListener('viewState.currentPageIndex.change', (page) =>
      setOnPageIndex(page),
    );

    //@ts-ignore
    const cont = inst.contentDocument.host;
    cont.ondrop = async (e: any) =>
      await handleDrop(
        e,
        inst,
        PSPDFKit,
        currSigneeRef,
        currUserRef,
        onPageIndexRef,
      );

    inst.addEventListener(
      'annotations.press',
      (event: { preventDefault?: any; annotation?: any }) => {
        if (!viewer.current) {
          return; // Prevents error when the viewer is not mounted
        }
        const { annotation } = event;
        if (annotation.customData?.isInitial) {
          setSessionInitials((prev: any) => [...prev, annotation]);
          isCreateInitial = true;
        } else {
          setSessionSignatures((prev: any) => [...prev, annotation]);
          isCreateInitial = false;
        }

        if (
          !isTextAnnotationMovableRef.current &&
          annotation instanceof PSPDFKit.Annotations.TextAnnotation
        ) {
          event.preventDefault();
        }

        //If it's a text widget show duplicate in popup
        setTimeout(() => {
          const popupFooterDiv = inst.contentDocument.querySelector(
            '.PSPDFKit-2wtzexryxvzwm2ffu1e1vh391u',
          );
          const existingDuplicateButton = inst.contentDocument.querySelector(
            `.PSPDFKit-Form-Creator-Editor-Duplicate`,
          );
          // Remove existing duplicate button if it exists
          if (existingDuplicateButton) {
            existingDuplicateButton.remove();
          }

          // Add duplicate button if it doesn't exist
          if (popupFooterDiv) {
            const duplicateButtonHTML = document.createElement('button');
            duplicateButtonHTML.innerHTML = 'Duplicate';
            duplicateButtonHTML.className =
              'PSPDFKit-239sjtxdzvwfjbe3ass6aqfp77 PSPDFKit-7kqbgjh4u33aw27zwkrtu57p2e PSPDFKit-68w1xn1tjp178f46bwdhvjg7f1 PSPDFKit-Form-Creator-Editor-Duplicate ' +
              annotation.Id;
            duplicateButtonHTML.onclick = async () => {
              await onPressDuplicate(annotation, PSPDFKit, trackInst);
            };
            popupFooterDiv.appendChild(duplicateButtonHTML);
          }
        }, 1);

        if (
          !isTextAnnotationMovableRef.current &&
          event.annotation instanceof PSPDFKit.Annotations.TextAnnotation
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          event.preventDefault();
        }
      },
    );

    inst.addEventListener(
      'storedSignatures.create',
      async (annotation: any) => {
        if (isCreateInitial) {
          setSessionInitials([...sessionInitials, annotation]);
        } else {
          setSessionSignatures([...sessionSignatures, annotation]);
        }
      },
    );

    inst.addEventListener(
      'annotations.create',
      async function (createdAnnotations: any) {
        const annotation = createdAnnotations.get(0);
        await handleAnnotationCreation(
          inst,
          annotation,
          mySignatureIdsRef,
          setSignatureAnnotationIds,
          currUser.email,
        );
      },
    );

    inst.addEventListener(
      'annotations.delete',
      async (deletedAnnotations: any) => {
        const annotation = deletedAnnotations.get(0);
        await handleAnnotationDelete(inst, annotation, currUser?.email);
        const updatedAnnotationIds = mySignatureIdsRef.current.filter(
          (id) => id !== annotation.id,
        );
        setSignatureAnnotationIds(updatedAnnotationIds);
        mySignatureIdsRef.current = updatedAnnotationIds;
      },
    );

    inst.setViewState((viewState) =>
      viewState.set('interactionMode', PSPDFKit.InteractionMode.FORM_CREATOR),
    );
    setIsTextAnnotationMovable(true);
  };

  const onSave = async () => {
    const instantJSON = await generateInstantJSON(instance, PSPDFKit);
    const mappingInstantJSON =
      updateAnnotationIdsBasedOnPdfObjectId(instantJSON);
    mutate(
      {
        fileId,
        liveFormData: {
          xfdf_data: '',
          json_data: JSON.stringify(mappingInstantJSON),
          type: liveFormType,
        },
      },
      {
        onSuccess: () => {
          toast.show({
            text: 'File data saved successfully',
            variant: 'success',
          });
          handleClose();
        },
        onError: (e) => {
          if ((e as AxiosError).status === 500) {
            toast.show({
              text: 'Internal Server Error',
              variant: 'error',
            });
          } else if ((e as AxiosError).status === 422) {
            toast.show({
              text: 'The selected type is invalid',
              variant: 'error',
            });
          }
        },
      },
    );
  };

  const applyAndSave = async () => {
    try {
      await onSave();
    } catch (e) {
      console.error(e);
      toast.show({
        text: 'Something went wrong! Reload the page and try again',
        variant: 'error',
      });
    }
  };

  const handleClose = async () => {
    await cleanupPSPDFKit();
    onClose();
  };

  return (
    <Dialog
      id="web-viewer-modal"
      fullScreen
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
    >
      <StyledHeader>
        <HeaderTitle>{fileName}</HeaderTitle>
        <StyledHeaderActions spacing={1} direction="row">
          <Button variant="outlined" color="tertiary" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={applyAndSave}
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </StyledHeaderActions>
      </StyledHeader>
      <StyledWebViewerBox>
        <SidePanel
          users={contacts}
          currSignee={currSignee}
          onDragStart={(event: React.DragEvent<HTMLDivElement>, type: string) =>
            onDragStart(event, type, currSignee, instance, liveFormType)
          }
          onDragEnd={onDragEnd}
          isTemplate={true}
          type={liveFormType}
        />
        <StyledViewer ref={viewer} onDragOver={handleDragOver} />
      </StyledWebViewerBox>
    </Dialog>
  );
};
