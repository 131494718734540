import { getCodeByCountryIso2 } from '@components/lib/form-phone/country';
import { TAddContactRequest } from '@gen2/api/invite-contacts/api';
import { TDraftInviteRequest, TEditDraftRequest } from '@gen2/api/invites/api';
import { TAddContactForm } from '@gen2/types/contact';
import { TDraftInviteForm } from './send-invites';

export const createDraftPayload = (
  values: TDraftInviteForm,
): TDraftInviteRequest => {
  const payload: TDraftInviteRequest = {
    from_user_id: values.from_user_id,
    subject: values.subject,
    message: values.message,
    contact_ids: values.contacts?.length
      ? values.contacts.map(({ id }) => id)
      : [],
    contact_group_ids: values.contact_group_ids?.length
      ? values.contact_group_ids
      : [],
    due_at: values.due_at ? values.due_at.format() : undefined,
    meta: values?.meta,
  };

  return payload;
};

export const createEditDraftPayload = (
  id: string,
  values: TDraftInviteForm,
): TEditDraftRequest => {
  return {
    id,
    from_user_id: values.from_user_id,
    subject: values.subject,
    message: values.message,
    due_at: values.due_at ? values.due_at.format() : undefined,
    meta: values?.meta,
    overdue_reminder: Number(
      values.overdue_reminder === 'custom'
        ? values.customOverdueValue
        : values.overdue_reminder,
    ),
    almost_due_reminder: Number(
      values.almost_due_reminder === 'custom'
        ? values.customAlmostDueValue
        : values.almost_due_reminder,
    ),
    assignee_user_id: values.assignee_user_id,
  };
};

export const createContactPayload = (
  contact: TAddContactForm,
): TAddContactRequest => {
  let payload: TAddContactRequest = {
    first_name: contact.first_name,
    last_name: contact.last_name,
    email: contact.email,
  };

  if (contact.number && contact.country_iso2_code) {
    const countryCode = getCodeByCountryIso2(contact.country_iso2_code);

    if (countryCode) {
      payload = {
        ...payload,
        phone: {
          number: contact.number,
          country_iso2_code: contact.country_iso2_code,
          country_number_code: `+${countryCode}`,
        },
      };
    }
  }

  return payload;
};

export const preventDefault = (event: React.SyntheticEvent) =>
  event.preventDefault();
