/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */
import { useGetLiveFormQuery } from '@gen2/api/live-form/hooks';
import { Button, Dialog } from '@mui/material';
import { omit } from 'lodash';
import PSPDFKit, { Instance } from 'pspdfkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce, useUnmount } from 'usehooks-ts';
import { TOOLBAR_ITEMS } from './nutrient/utils/common-helpers';
import getAnnotationRenderers from './nutrient/utils/customAnnotationRenderer';
import customizeUIForInitials from './nutrient/utils/customizeUIForInitials';
import {
  HeaderTitle,
  StyledHeader,
  StyledHeaderActions,
} from './peview-web-viewer-modal.styled';

export type TWebViewerModalProps = {
  isOpen: boolean;
  fileId: string;
  fileName: string;
  fileUrl: string;
  onClose: () => void;
};
export const PreviewNutrientViewerModal = ({
  isOpen,
  fileId,
  fileName,
  fileUrl,
  onClose,
}: TWebViewerModalProps) => {
  const viewer2 = useRef(null);
  const [instance, setInstance] = useState<any>(null);
  const beenInitialised = useRef<boolean>(false);
  // State to store the current page index
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;

  const [isTextAnnotationMovable, setIsTextAnnotationMovable] = useState(false);
  const isTextAnnotationMovableRef = useRef(isTextAnnotationMovable);
  isTextAnnotationMovableRef.current = isTextAnnotationMovable;

  const { data } = useGetLiveFormQuery(fileId);
  const instantJSON = useMemo(() => {
    if (data?.liveform?.fields?.json_data) {
      return JSON.parse(data.liveform.fields.json_data);
    }
    return '';
  }, [data]);

  const isCreateInitial = false;
  let trackInst: any = null;
  const loadPSPDFKit = async (el?: HTMLElement) => {
    if (PSPDFKit && fileUrl && el && !beenInitialised.current) {
      PSPDFKit.unload(el);
      beenInitialised.current = true;
      try {
        const {
          UI: { createBlock, Recipes, Interfaces },
        } = PSPDFKit;
        await PSPDFKit.load({
          container: el,
          document: fileUrl,
          // licenseKey: customDecode(window.$xr9c42),
          baseUrl: `${window.location.protocol}//${window.location.host}/assets/lib/pspdfkit/`,
          toolbarItems: [...TOOLBAR_ITEMS],
          disableTextSelection: false,
          styleSheets: [`/assets/lib/pspdfkit/viewer.css`],
          ui: {
            [Interfaces.CreateSignature]: ({ props }) => ({
              content: createBlock(Recipes.CreateSignature, props, ({ ui }) => {
                if (isCreateInitial) ui = customizeUIForInitials(ui);
                return ui.createComponent();
              }).createComponent(),
            }),
          },
          customRenderers: {
            Annotation: ({ annotation }) => {
              return getAnnotationRenderers({ annotation });
            },
          },
        }).then((instance) => {
          trackInst = instance;
          setInstance(instance);
          configureInstanceEvents(instance); // Configure instance-specific events
        });
      } catch (error) {
        console.error('Error loading PSPDFKit:', error);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setTimeout(() => loadPSPDFKit(viewer2.current!));
    }
  };

  useEffectOnce(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    loadPSPDFKit(viewer2.current!);
  });

  useEffect(() => {
    const loadAnnotations = async () => {
      if (instance && instantJSON) {
        await instance.applyOperations([
          {
            type: 'applyInstantJson',
            instantJson: omit(instantJSON, ['pdfId']),
          },
        ]);
      }
    };
    loadAnnotations();
  }, [instance, instantJSON]);

  const configureInstanceEvents = (inst: Instance) => {
    // Additional configurations and event listeners for the PSPDFKit instance

    inst.setViewState((viewState) =>
      viewState.set(
        'showSignatureValidationStatus',
        PSPDFKit.ShowSignatureValidationStatusMode.IF_SIGNED,
      ),
    );

    inst.addEventListener('viewState.currentPageIndex.change', (page) =>
      setOnPageIndex(page),
    );

    setIsTextAnnotationMovable(false);
  };

  const handleClose = async () => {
    await cleanupPSPDFKit();
    onClose();
  };

  const cleanupPSPDFKit = async () => {
    if (instance && viewer2.current) {
      await PSPDFKit.unload(viewer2.current);
      viewer2.current = null;
      setInstance(null);
    }
  };

  useUnmount(async () => {
    await cleanupPSPDFKit();
  });

  return (
    <Dialog
      id="preview-modal"
      fullScreen
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown
    >
      <StyledHeader>
        <HeaderTitle>{fileName}</HeaderTitle>
        <StyledHeaderActions spacing={1} direction="row">
          <Button variant="outlined" color="tertiary" onClick={handleClose}>
            Close
          </Button>
        </StyledHeaderActions>
      </StyledHeader>
      <div ref={viewer2} style={{ height: '100vh' }} />
    </Dialog>
  );
};
