import { useSafeQuery } from '@gen2/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { has } from 'lodash';
import {
  createFollowUp,
  getFollowUp,
  getFollowUps,
  mergeTemplates,
  selectTemplate,
  sendFollowUp,
  TGetFollowUpQueryParams,
  TGetFollowUpResponse,
  updateFollowUp,
} from './api';

export enum FollowUpKeys {
  createFollowUp = 'createFollowUp',
  updateFollowUp = 'updateFollowUp',
  getFollowUp = 'getFollowUp',
  getFollowUps = 'getFollowUps',
  sendFollowUp = 'sendFollowUp',
  mergeTemplates = 'mergeTemplates',
  selectTemplate = 'selectTemplate',
}

export const useCreateFollowUpMutation = () =>
  useMutation({
    mutationKey: [FollowUpKeys.createFollowUp],
    mutationFn: createFollowUp,
  });

export const useGetFollowUpQuery = ({
  inviteId,
  followUpId,
  options
}: TGetFollowUpQueryParams) =>
  useSafeQuery<TGetFollowUpResponse['data']['follow_up']>(
    [FollowUpKeys.getFollowUp, Object.values([inviteId, followUpId]).toString()],
    async () => {
      const { data } = await getFollowUp({ inviteId, followUpId });

      return data?.data.follow_up;
    },
    options,
  );

export const useGetFollowUpsQuery = ({ inviteId }: { inviteId: string }) =>
  useQuery({
    enabled: !!inviteId,
    queryKey: [FollowUpKeys.getFollowUps, [inviteId]],
    queryFn: async () => {
      try {
        const { data } = await getFollowUps({ inviteId });

        if (data && has(data, 'data') && has(data.data, 'follow_ups')) {
          return data.data.follow_ups;
        }

        return null;
      } catch (error) {
        return null;
      }
    },
  });

export const useUpdateFollowUpMutation = () =>
  useMutation({
    mutationKey: [FollowUpKeys.updateFollowUp],
    mutationFn: updateFollowUp,
  });

export const useSendFollowUpMutation = () =>
  useMutation({
    mutationKey: [FollowUpKeys.sendFollowUp],
    mutationFn: sendFollowUp,
  });

export const useFollowUpSelectTemplateMutation = () =>
  useMutation({
    mutationFn: selectTemplate,
    mutationKey: [FollowUpKeys.selectTemplate],
  });

export const useFollowUpMergeTemplatesMutation = () =>
  useMutation({
    mutationFn: mergeTemplates,
    mutationKey: [FollowUpKeys.mergeTemplates],
  });
