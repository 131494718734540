import dayjs, { ManipulateType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(utc);

/***
 * @param utcDate  it is an UTC time
 * **/
type UtcDate = string | number | Date | dayjs.Dayjs | null | undefined;

export const timeToUtcObject = (utcDate: UtcDate) => {
  if (!utcDate) return '-';
  return dayjs.utc(utcDate);
};

export const utcToLocalDate = (utcDate: UtcDate) => {
  if (!utcDate) return '-';
  return dayjs.utc(utcDate).local().format('DD MMM, YYYY');
};
export const increaseTimeToLocal = (
  utcDate: UtcDate,
  n = 0,
  unit: ManipulateType = 'hour',
) =>
  !utcDate
    ? '-'
    : dayjs.utc(utcDate).local().add(n, unit).format('MMM DD, YYYY h:mm A');

export const diffFromNow = (utcDate: UtcDate) =>
  !utcDate ? '-' : dayjs.utc(utcDate).diff(dayjs().utc());



export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(1), ms || 1000));

export const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// in case 00:00:00 start of day will fail in the endpoinn
export const toEndDayOfOrgTimezone = (utcDate: UtcDate) => {
  if (!utcDate) return null;
  return dayjs(utcDate).endOf('day');
}

export const utcToOrgTimezone = (utcDate: UtcDate, timezone: string | undefined) => {
  if (!utcDate) return null;
  return dayjs(utcDate).tz(timezone).format('DD MMM, YYYY');
}

export const formatDate = (rawDate: Date | string | undefined): string => {
  if (!rawDate) return '';
  return dayjs(rawDate).format('DD-MMM-YY, HH:mm');
};

export const formatDateToOrgTimezone = (rawDate: Date | string | undefined, timezone: string | undefined, format = 'DD-MMM-YY, HH:mm'): string => {
  if (!rawDate) return '';
  return dayjs(rawDate).tz(timezone).format(format);
};
