import {
  StyledFileUpload,
  StyledFileUploadActions,
  StyledFileUploadCancel,
  StyledFileUploadFileSize,
  StyledFileUploadIconButton,
  StyledFileUploadIconButtonCancel,
  StyledFileUploadIconButtonCancelElement,
  StyledFileUploadInfo,
  StyledFileUploadMore,
  StyledIconButton,
} from '@components/lib/file-upload/file-upload.styles';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetFileUrlQuery } from '@gen2/api/files/hooks';
import { FeatureFlags } from '@gen2/app/components/feature-flags/feature-flags';
import { NutrientFormViewerModal } from '@gen2/app/components/invite-request/variant/live-form/nutrient-form-viewer-modal';
import {
  LiveFormType,
  useSendInviteStore,
} from '@gen2/app/invites/send-invites/store';
import { useAuth, useRouter } from '@gen2/hooks';
import {
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useUnmount } from 'usehooks-ts';
import { CreateFormModal } from './create-form-modal';
import { useLiveFormStore } from './liveFormStore';
import { PreviewNutrientViewerModal } from './preview-nutrient-viewer-modal';
import { PreviewWebViewerModal } from './preview-web-viewer-modal';
import { TemplateCreateFormModal } from './template-create-form-modal';
import { TemplateNutrientWebViewerModal } from './template-nutrient-web-viewer-modal';
import { TemplateWebViewerModal } from './template-web-viewer-modal';
import { WebViewerModal } from './web-viewer-modal';

/* eslint-disable-next-line */
export interface LiveFormControlsProps {
  key: string;
  progress: number;
  children?: React.ReactNode;
  fileId: string;
  fileSize: string;
  fileName: string;
  isUploading?: boolean;
  icon?: React.ReactNode;
  onDelete?: () => void;
}

export interface FileUploadActionProps {
  children?: React.ReactNode;
  tablet?: boolean;
}

export const FileUploadActions = ({
  children,
  tablet,
}: FileUploadActionProps) => {
  return (
    <StyledFileUploadActions $tablet={tablet}>
      {children}
    </StyledFileUploadActions>
  );
};

const noop = () => {
  return;
};

export const LiveFormControls = ({
  children,
  fileName,
  fileId,
  progress,
  fileSize,
  isUploading = false,
  icon,
  onDelete,
  ...rest
}: LiveFormControlsProps) => {
  const { featureFlags } = useAuth();
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up('sm'));
  const store = useSendInviteStore();
  const router = useRouter();
  const {
    isCreateFormModalOpen,
    isWebViewerModalOpen,
    isNutrientViewerModalOpen,
    isNutrient,
    setIsCreateFormModalOpen,
    setIsWebViewerModalOpen,
    setIsNutrientViewerModalOpen,
    setIsNutrient,
    reset,
  } = useLiveFormStore();
  // preview original viewer
  const [isPreviewViewerOpen, setIsPreviewViewerOpen] = useState(false);
  // preview nutrient viewer
  const [isPreviewNutrientViewerOpen, setIsNutrientPreviewViewerOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [fileUrl, setFileUrl] = useState('');
  const { data, isLoading } = useGetFileUrlQuery({
    fileId,
  });

  useEffect(() => {
    if (data) {
      setFileUrl(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  //don't update this!!

  useUnmount(() => {
    reset();
  });

  const isInTemplates = router.pathname.includes('templates');

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: () => void) => () => {
    setAnchorEl(null);
    event();
  };

  const preview = (event: () => void) => () => {
    setIsPreviewViewerOpen(true);
    event();
  };

  const previewNutrient = (event: () => void) => () => {
    setIsNutrientPreviewViewerOpen(true);
    event();
  };

  const makeNutrientForm = (event: () => void) => () => {
    setIsCreateFormModalOpen(true);
    setIsNutrient(true);
    event();
  };

  const makeFillableForm = (event: () => void) => () => {
    setIsCreateFormModalOpen(true);
    setIsNutrient(false);
    event();
  };

  const handleOnSelected = (liveFormType: LiveFormType) => {
    store.setLiveFormType(liveFormType);
    handleCreateFormModalClose();
    setIsWebViewerModalOpen(true);
  };

  const handleNutrientSelected = (liveFormType: LiveFormType) => {
    store.setLiveFormType(liveFormType);
    handleCreateFormModalClose();
    setIsNutrientViewerModalOpen(true);
  };

  const handleCreateFormModalClose = () => {
    setIsCreateFormModalOpen(false);
  };

  return (
    <>
      <StyledFileUpload
        $progress={progress}
        $uploading={isUploading}
        $tablet={tablet}
        {...rest}
      >
        <StyledFileUploadInfo>
          {icon}
          <span>{fileName}</span>
          <StyledFileUploadFileSize>{fileSize}</StyledFileUploadFileSize>
        </StyledFileUploadInfo>
        {tablet ? (
          <FileUploadActions tablet={tablet}>
            <StyledIconButton
              variant="outlined"
              color="tertiary"
              onClick={makeFillableForm(noop)}
              disabled={isLoading}
            >
              Edit Form Fields
            </StyledIconButton>
            <FeatureFlags restriction="pspdfkit">
              <StyledIconButton
                variant="outlined"
                color="tertiary"
                onClick={makeNutrientForm(noop)}
                tabIndex={-1}
                disabled={isLoading}
              >
                Edit Nutrient form
              </StyledIconButton>
            </FeatureFlags>
            <Tooltip title="View">
              <span>
                <StyledFileUploadIconButton
                  onClick={preview(noop)}
                  color="tonalLight"
                  disabled={isLoading}
                >
                  <FontAwesomeIcon icon={regular('eye')} />
                </StyledFileUploadIconButton>
              </span>
            </Tooltip>
            <FeatureFlags restriction="pspdfkit">
              <Tooltip title="View Nutrient">
                <span>
                  <StyledFileUploadIconButton
                    onClick={previewNutrient(noop)}
                    color="tonalLight"
                    disabled={isLoading}
                  >
                    <FontAwesomeIcon icon={regular('eye')} />
                  </StyledFileUploadIconButton>
                </span>
              </Tooltip>
            </FeatureFlags>
            {isUploading ? (
              <StyledFileUploadIconButtonCancel $progress={progress}>
                <StyledFileUploadIconButtonCancelElement>
                  <FontAwesomeIcon icon={regular('close')} />
                </StyledFileUploadIconButtonCancelElement>
              </StyledFileUploadIconButtonCancel>
            ) : (
              <Tooltip title="Delete">
                <StyledFileUploadIconButton
                  data-cy="delete"
                  onClick={onDelete}
                  color="tonalLight"
                >
                  <FontAwesomeIcon icon={regular('trash-can')} />
                </StyledFileUploadIconButton>
              </Tooltip>
            )}
          </FileUploadActions>
        ) : (
          <StyledFileUploadActions $tablet={tablet}>
            {isUploading ? (
              <StyledFileUploadCancel>
                <FontAwesomeIcon size="xl" icon={regular('close')} />
              </StyledFileUploadCancel>
            ) : (
              <StyledFileUploadMore
                id="file-upload-menu-btn"
                data-testid="file-upload-menu-btn"
                aria-controls={open ? 'file-upload-menu' : ''}
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <FontAwesomeIcon
                  size="xl"
                  icon={regular('ellipsis-vertical')}
                />
              </StyledFileUploadMore>
            )}
          </StyledFileUploadActions>
        )}
        {!tablet && (
          <Menu
            id="file-upload-menu"
            data-testid="file-upload-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose(noop)}
            MenuListProps={{
              'aria-labelledby': 'file-upload-menu-btn',
            }}
          >
            <MenuItem onClick={handleMenuClose(onDelete ?? noop)}>
              Delete
            </MenuItem>
          </Menu>
        )}
        {children}
      </StyledFileUpload>

      {isPreviewViewerOpen && (
        <PreviewWebViewerModal
          isOpen={isPreviewViewerOpen}
          fileId={fileId}
          fileName={fileName}
          onClose={() => setIsPreviewViewerOpen(false)}
        />
      )}

      {isPreviewNutrientViewerOpen && (
        <PreviewNutrientViewerModal
          isOpen={isPreviewNutrientViewerOpen}
          fileId={fileId}
          fileName={fileName}
          fileUrl={fileUrl}
          onClose={() => setIsNutrientPreviewViewerOpen(false)}
        />
      )}

      {isWebViewerModalOpen &&
        (!isInTemplates ? (
          <WebViewerModal
            fileId={fileId}
            fileUrl={fileUrl}
            fileName={fileName}
            open={isWebViewerModalOpen}
            onClose={() => setIsWebViewerModalOpen(false)}
          />
        ) : !featureFlags?.pspdfkit ? (
          <TemplateWebViewerModal
            fileId={fileId}
            fileUrl={fileUrl}
            fileName={fileName}
            type={store.liveFormType}
            open={isWebViewerModalOpen}
            onClose={() => setIsWebViewerModalOpen(false)}
          />
        ) : (
          <TemplateNutrientWebViewerModal
            fileId={fileId}
            fileUrl={fileUrl}
            fileName={fileName}
            type={store.liveFormType}
            open={isWebViewerModalOpen}
            onClose={() => setIsWebViewerModalOpen(false)}
          />
        ))}

      {isNutrientViewerModalOpen && (
        <NutrientFormViewerModal
          fileId={fileId}
          fileUrl={fileUrl}
          fileName={fileName}
          open={isNutrientViewerModalOpen}
          onClose={() => setIsNutrientViewerModalOpen(false)}
        />
      )}

      {isCreateFormModalOpen &&
        (isInTemplates ? (
          <TemplateCreateFormModal
            fileId={fileId}
            open={isCreateFormModalOpen}
            onClose={handleCreateFormModalClose}
            onSelected={handleOnSelected}
            fileUrl={fileUrl}
          />
        ) : isNutrient ? (
          <CreateFormModal
            fileId={fileId}
            open={isCreateFormModalOpen}
            onClose={handleCreateFormModalClose}
            onSelected={handleNutrientSelected}
            fileUrl={fileUrl}
          />
        ) : (
          <CreateFormModal
            fileId={fileId}
            open={isCreateFormModalOpen}
            onClose={handleCreateFormModalClose}
            onSelected={handleOnSelected}
            fileUrl={fileUrl}
          />
        ))}
    </>
  );
};

export default LiveFormControls;
