import { TContact } from "@gen2/types/contact";

export enum AnnotationTypeEnum {
  NAME = "name",
  SIGNATURE = "signature",
  DATE = "date",
  INITIAL = "initial",
  DS = "ds",
  RadioButton = "RadioButton",
  CheckBox = "CheckBox",
  TextField = "TextField",
}

export interface AIMessage {
  role: string;
  content: string;
}

export type TAssignee = Pick<
  TContact,
  'id' | 'email' | 'first_name' | 'last_name'
> & {
  color?: any;
  role?: string;
};
