export const licenseKey = () => {
  return 'FileInvite Limited:OEM:FileInvite Limited::B+:AMS(20250304):C7DC3F0C074A2AF37B313AC9B24338264E6F7FC98B46E08D4215591C1C00F65AAAB6F5C7';
}

export const nutrientKey = () => {
  return (process.env['NX_RUNTIME_ENV'] === 'prod') ? '3-a624e3d9c4ca7e7db08783b96f1e1f77' : '3-54a06f1c308b31fca810785156860e84';
}

export function customEncode(key: string) {
    const modifiedKey = key.split('').map(char => char + '*').join('');
    return window.btoa(modifiedKey);
}

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $zfeg48: any;
        $xr9c42: any;
    }
}

window.$zfeg48 = customEncode(licenseKey());
window.$xr9c42 = customEncode(nutrientKey());

export function customDecode(encodedKey: string) {
    const decodedKey = window.atob(encodedKey);
    return decodedKey.split('').filter((_, index) => index % 2 === 0).join('');
}
