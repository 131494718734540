import { colors, weights } from '@components/theme/gen2';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  IconButton,
  Avatar as MuiAvatar,
  styled,
} from '@mui/material';
import { rem } from 'polished';

// This page is a temporary styles page shared by app and portal
// Will be moving this page and its contents to the appropriate location
// Once the total trailers is complete and customers have been onboarded
//
// TODO:
// - [] Create component for new accordion styles
// - [] Create component for new file styles
export const RequestSection = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))({
  borderRadius: rem(4),
  border: `1px solid ${colors.grayLight2}`,

  '&::before': {
    display: 'none',
  },
});

export const RequestSectionHead = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<FontAwesomeIcon icon={solid('chevron-down')} />}
    {...props}
  />
))({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  gap: rem(10),
  fontSize: rem(15),
  fontWeight: weights.semiBold,
  color: colors.grayDark3,
});

export const RequestSectionContent = styled(AccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(10),
  padding: rem(12),
  backgroundColor: colors.grayLight3,
});

export const EmptyState = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: rem(14),
  fontWeight: weights.medium,
  color: colors.grayDark1,
  backgroundColor: colors.white,
  border: `1px solid ${colors.grayLight2}`,
  paddingTop: rem(13),
  paddingBottom: rem(13),
  borderRadius: rem(4),
});

// ====================================================

export const StyledFile = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  gap: rem(16),
  border: `1px solid ${colors.grayLight2}`,
  height: rem(50),
  backgroundColor: colors.white,
  borderRadius: rem(4),
  paddingLeft: rem(16),
  paddingRight: rem(16),
});

export const StyledFileInfo = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});

export const StyledFileInfoIcon = styled('span')({
  fontSize: rem(15),
  color: colors.grayDark2,
  marginLeft: rem(18),
  marginRight: rem(12),
});

export const StyledFileInfoText = styled('span')({
  fontSize: rem(14),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledFileActions = styled('div')({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  gap: rem(5),
  color: colors.grayDark2,
  fontSize: rem(14),
  fontWeight: 500,
});

// ====================================================
// Components
// ====================================================

export const Avatar = styled(MuiAvatar)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: rem(32),
  height: rem(32),
  borderRadius: '50%',
  backgroundColor: 'transparent',
  color: colors.grayDark3,
  fontSize: rem(14),
  border: `1px solid ${colors.grayDark3}`,
});

export const FileAction = styled(IconButton)({
  width: rem(32),
  height: rem(32),
  backgroundColor: colors.secondaryLight4,
  color: colors.secondaryDark3,
  fontSize: rem(14),
});

// ====================================================
// Updates
// ====================================================

export const UpdateContent = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: rem(10),
  backgroundColor: colors.white,
  padding: rem(10),
  border: `1px solid ${colors.grayLight2}`,
  borderRadius: rem(4),
});

export const UpdateTitle = styled('div')({
  fontWeight: weights.bold,
  color: colors.grayDark3,
});

export const UpdateMessage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: rem(13),
});
